<template>
  <div class="children">
    <div class="children-title">
      <span>严重精神障碍患者随访服务记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <!-- <div class="position">
        <span>产科检</span>
      </div> -->
      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>随访时间</span>
        </div>
        <div class="children-td children-flex3 children-text-center children-border-right-none">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
        <div class="children-td children-flex5 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>本次随访形式</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>若失访，原因</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">外出打工</el-radio>
            <el-radio label="1">迁居他处</el-radio>
            <el-radio label="2">走失</el-radio>
            <el-radio label="3">连续</el-radio>
            <el-radio label="4">连续3次未到访</el-radio>
            <el-radio label="5">其他</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span></span>
        </div>
        <div class="children-td children-flex2 children-text-center">
          <span class="color">死亡日期</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span class="color">年 月 日</span>
        </div>
        <div class="children-td children-flex5 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <span>如死亡，日期和原因</span>
        </div>
        <div class="children-td children-flex2 children-text-center">
          <span class="color">死亡原因</span>
        </div>
        <div class="children-td children-flex8 children-border-right-none children-td-column">
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex2 children-border-right-none">
              <span class="color">1 躯体疾病</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">阳传染病和寄生虫病性</el-radio>
                <el-radio label="1">肿瘤</el-radio>
                <el-radio label="2">心脏病</el-radio>
                <el-radio label="1">脑血管病</el-radio>
                <el-radio label="2">呼吸系统疾病</el-radio>
                <el-radio label="1">消化系统疾病</el-radio>
                <el-radio label="2">其他疾病</el-radio>
                <el-radio label="2">不详</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-tr">
            <div class="children-td children-flex2 children-border-right-none">
              <span class="color">2 自杀</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">自杀</el-radio>
                <el-radio label="1">他杀</el-radio>
                <el-radio label="2">意外</el-radio>
                <el-radio label="3">精神疾病相关并发症 </el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>危险性评估</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">0 （0级）</el-radio>
            <el-radio label="1">1（1级）</el-radio>
            <el-radio label="2">2(2级)</el-radio>
            <el-radio label="3">3(3级)</el-radio>
            <el-radio label="4">4(4级)</el-radio>
            <el-radio label="5">5（5级）</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <span>目前症状</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0" class="margin-bottom">幻觉</el-checkbox>
            <el-checkbox label="1" class="margin-bottom">交流困难</el-checkbox>
            <el-checkbox label="2" class="margin-bottom">猜疑</el-checkbox>
            <el-checkbox label="3" class="margin-bottom">喜怒无常</el-checkbox>
            <el-checkbox label="4" class="margin-bottom">行为怪异</el-checkbox>
            <el-checkbox label="5" class="margin-bottom">兴奋话多</el-checkbox>
            <el-checkbox label="6" class="margin-bottom">伤人毁物</el-checkbox>
            <el-checkbox label="7" class="margin-bottom">悲观厌世</el-checkbox>
            <el-checkbox label="8" class="margin-bottom">无故外走</el-checkbox>
            <el-checkbox label="9" class="margin-bottom">自语自笑</el-checkbox>
            <el-checkbox label="10" class="margin-bottom">孤僻懒散</el-checkbox>
            <el-checkbox label="11" class="margin-bottom">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>自知力</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">自知力完全</el-radio>
            <el-radio label="1">自知力不全</el-radio>
            <el-radio label="2">自知力缺失</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>睡眠情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">良好 </el-radio>
            <el-radio label="1">一般</el-radio>
            <el-radio label="2">较差</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>饮食情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">良好 </el-radio>
            <el-radio label="1">一般</el-radio>
            <el-radio label="2">较差</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr5 children-border">
        <div class="children-td children-flex2">
          <span>社会功能情况</span>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex2">
              <span>个人生活料理</span>
            </div>
            <div class="children-td children-flex10 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">良好 </el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">较差</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex2">
              <span>家务劳动</span>
            </div>
            <div class="children-td children-flex10 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">良好 </el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">较差</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex2">
              <span>生产劳动及工作</span>
            </div>
            <div class="children-td children-flex10 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">良好 </el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">较差</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex2">
              <span>学习能力</span>
            </div>
            <div class="children-td children-flex10 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">良好 </el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">较差</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-tr">
            <div class="children-td children-flex2">
              <span>社会人际交往</span>
            </div>
            <div class="children-td children-flex10 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">良好 </el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">较差</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>危险行为</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">轻度滋事次</el-checkbox>
            <el-checkbox label="1">肇事次</el-checkbox>
            <el-checkbox label="2">肇祸次</el-checkbox>
            <el-checkbox label="3">其他危害行为次</el-checkbox>
            <el-checkbox label="4">自伤次</el-checkbox>
            <el-checkbox label="5">自杀未遂次</el-checkbox>
            <el-checkbox label="6">无</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>两次随访期间关锁情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无关锁</el-radio>
            <el-radio label="1">关锁</el-radio>
            <el-radio label="2">关锁已解除</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>两次随访期间住院情况</span>
        </div>
        <div class="children-td children-flex6 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未住院</el-radio>
            <el-radio label="1">目前正在住院</el-radio>
            <el-radio label="2">曾住院，现未住院</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex4 children-border-right-none">
          <span class="color">末次出院时间    年    月    日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>实验室检查</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>用药依从性</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">按医嘱规律用药</el-radio>
            <el-radio label="1">间断用药</el-radio>
            <el-radio label="2">不用药</el-radio>
            <el-radio label="3">医嘱勿需用药</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>药物不良反应</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>治疗效果</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">痊愈</el-radio>
            <el-radio label="1">好转</el-radio>
            <el-radio label="2">无变化</el-radio>
            <el-radio label="3">加重</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr2 children-border">
        <div class="children-td children-flex2">
          <span>是否转诊</span>
        </div>
        <div class="children-td children-flex10 children-wrap children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr7 children-border">
        <div class="children-td children-flex2">
          <span>用药情况</span>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
          <div class="children-tr">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr7 children-border">
        <div class="children-td children-flex2">
          <span>用药指导</span>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
          <div class="children-tr">
            <div class="children-td children-flex1 children-text-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td children-flex1 children-text-center">
              <span>用法：  每日(月)   次</span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>每次剂量        mg</span>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>康复措施</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-checkbox-group v-model="sex" class="margin-left">
            <el-checkbox label="0">生活劳动能力</el-checkbox>
            <el-checkbox label="1">职业训练</el-checkbox>
            <el-checkbox label="2">学习能力</el-checkbox>
            <el-checkbox label="3">社会交往</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>本次随访分类</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">不稳定</el-radio>
            <el-radio label="1">基本稳定</el-radio>
            <el-radio label="2">自服药</el-radio>
            <el-radio label="3">稳定</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>下次随访时间</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span class="color">年 月 日</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span class="color">随访医生签名</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #fff;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        white-space: nowrap;
        color: #000;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      // padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
