<template>
  <div class="write">
    <div class="title">
      <span>问诊设置</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="span">
          <span>图文问诊</span>
          <el-radio-group v-model="radio">
            <el-radio :label="3">关闭</el-radio>
            <el-radio :label="6">打开</el-radio>
          </el-radio-group>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="span">
          <span>图文问诊</span>
          <el-radio-group v-model="radio">
            <el-radio :label="3">关闭</el-radio>
            <el-radio :label="6">打开</el-radio>
          </el-radio-group>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="span">
          <span>图文问诊</span>
          <el-radio-group v-model="radio">
            <el-radio :label="3">关闭</el-radio>
            <el-radio :label="6">打开</el-radio>
          </el-radio-group>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      imageUrl: require('../../img/emr/user.png')
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 140px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 680px;
    height: 300px;
    margin-top: 10px;
    padding: 20px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 8px;
      right: 10px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      height: calc(100% - 70px);
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;

      .span {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          white-space: nowrap;
          margin-right: 10px;
        }

        .el-input {
          width: 170px;
          margin-left: 20px;
        }

        .el-radio-group {
          display: flex;
          align-items: center;
          margin-left: 20px;
        }
        /deep/.el-radio__label {
          color: #fff;
        }
        /deep/.el-radio__input.is-checked+.el-radio__label {
          color: #01EEFD;
        }
      }

    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
