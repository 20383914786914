import { render, staticRenderFns } from "./aiChronicDiseaseAssistantSystems.vue?vue&type=template&id=a3201fc6&scoped=true&"
import script from "./aiChronicDiseaseAssistantSystems.vue?vue&type=script&lang=js&"
export * from "./aiChronicDiseaseAssistantSystems.vue?vue&type=script&lang=js&"
import style0 from "./aiChronicDiseaseAssistantSystems.vue?vue&type=style&index=0&id=a3201fc6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3201fc6",
  null
  
)

export default component.exports