<template>
  <div class="write">
    <div class="title">
      <span>问诊详情</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="tab-title">
          <span>2021-07-21   张三丰医生     内分泌科   病情描述：深度佛塑否佛奥UFO阿UFO阿UFO芃</span>
        </div>
        <div class="tab-box">
          <div class="tab-item" :class="{ 'tab-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="tab-content" v-if="tabIndex === 0">
          <div class="tab-chat">
            <div class="tab-chat-title">
              <span>张三医生</span>
            </div>
            <div class="tab-chat-scroll">
              <div class="tab-chat-line">
                <div class="tab-chat-date">
                  <span>今天 10:20</span>
                </div>
                <div class="tab-chat-info">
                  <img src="../../img/emr/user.png" class="tab-chat-img">
                  <div class="tab-chat-news">
                    <span>症状描述：最近四个月左膝盖总是隐隐的疼，特别是晚上，去医院检查说是轻度硬化，请问吃药需要多长时间能有改善？</span>
                  </div>
                </div>
              </div>

              <div class="tab-chat-line">
                <div class="tab-chat-date">
                  <span>今天 10:20</span>
                </div>
                <div class="tab-chat-info tab-chat-info1">
                  <img src="../../img/emr/user.png" class="tab-chat-img">
                  <div class="tab-chat-news">
                    <span>症状描述：最近四个月左膝盖总是隐隐的疼，特别是晚上，去医院检查说是轻度硬化，请问吃药需要多长时间能有改善？</span>
                  </div>
                </div>
              </div>

              <div class="tab-chat-line">
                <div class="tab-chat-date">
                  <span>今天 10:20</span>
                </div>
                <div class="tab-chat-info">
                  <img src="../../img/emr/user.png" class="tab-chat-img">
                  <div class="tab-chat-news">
                    <span>症状描述：最近四个月左膝盖总是隐隐的疼，特别是晚上，去医院检查说是轻度硬化，请问吃药需要多长时间能有改善？</span>
                  </div>
                </div>
              </div>

              <div class="tab-chat-line">
                <div class="tab-chat-date">
                  <span>今天 10:20</span>
                </div>
                <div class="tab-chat-info tab-chat-info1">
                  <img src="../../img/emr/user.png" class="tab-chat-img">
                  <div class="tab-chat-news">
                    <span>症状描述：最近四个月左膝盖总是隐隐的疼，特别是晚上，去医院检查说是轻度硬化，请问吃药需要多长时间能有改善？</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-info" v-else-if="tabIndex === 1">
          <div class="tab-info-line">
            <span>问诊小结</span>
          </div>
          <div class="tab-info-line">
            <div class="tab-info-item">
              <span>患者姓名：田田 </span>
            </div>
            <div class="tab-info-item">
              <span>性别：男</span>
            </div>
            <div class="tab-info-item">
              <span>年龄：43</span>
            </div>
          </div>
          <div class="tab-info-line">
            <span>主诉：最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象</span>
          </div>
          <div class="tab-info-line">
            <span>病史：多少度佛奥UFO手动阀</span>
          </div>
          <div class="tab-info-line">
            <span>诊断名称：大肠杆菌PH指数偏高</span>
          </div>
        </div>
        <div class="tab-info" v-else-if="tabIndex === 2">
          <div class="tab-info-line">
            <span>处方</span>
          </div>
          <div class="tab-info-line">
            <div class="tab-info-item">
              <span>审核状态：审核通过</span>
            </div>
            <div class="tab-info-item">
              <span>药师：李四</span>
            </div>
            <div class="tab-info-item">
              <span>医生：张三</span>
            </div>
          </div>
          <div class="tab-info-line">
            <div class="tab-info-item">
              <span>科室：心内科</span>
            </div>
            <div class="tab-info-item">
              <span>初步诊断：原发性高血压</span>
            </div>
            <div class="tab-info-item">
              <span>西药处方    中药处方</span>
            </div>
          </div>
          <div class="tab-info-line">
            <span>Rp</span>
          </div>
          <div class="tab-info-line">
            <span>【络活喜】苯磺酸氨路地平片           ×1</span>
          </div>
          <div class="tab-info-line">
            <span>规格：5mg*7片/盒</span>
          </div>
          <div class="tab-info-line">
            <span>口服：成人一次5mg（1片）。每天一次</span>
          </div>
          <div class="tab-info-line">
            <span>【络活喜】苯磺酸氨路地平片           ×1</span>
          </div>
          <div class="tab-info-line">
            <span>规格：5mg*7片/盒</span>
          </div>
          <div class="tab-info-line">
            <span>口服：成人一次5mg（1片）。每天一次</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 2,
      tabs: Object.freeze(['问诊对话记录', '问诊小结', '处方'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 160px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 1135px;
    height: 700px;
    margin-top: 10px;
    padding: 20px 45px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      width: 100%;
      height: calc(100% - 70px);
      color: #01EEFD;
      font-size: 18px;

      .tab-title {
        height: 60px;
        line-height: 60px;
        padding-left: 70px;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 18px;
      }

      .tab-box {
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 70px;
        box-sizing: border-box;

        .tab-item {
          height: 38px;
          line-height: 38px;
          margin-right: 30px;
          padding: 0 21px;
          background-color: #284867;
          color: #FFFFFF;
          font-size: 18px;
          cursor: pointer;
        }

        .tab-active {
          background-color: #04B4D0;
        }
      }

      .tab-content {
        width: 100%;
        height: calc(100% - 100px);
        margin-top: 20px;
        padding: 9px 13px;
        background-color: #073A65;
        border: 1px solid #02467D;
        box-sizing: border-box;

        .tab-chat {
          width: 100%;
          height: 100%;
          background-color: #063155;
          border: 1px solid rgba(4, 180, 208, .4);

          .tab-chat-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #FFFFFF;
            font-size: 18px;
          }

          .tab-chat-scroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 60px);
            margin-top: 5px;
            padding: 0 10px;
            box-sizing: border-box;

            &::-webkit-scrollbar {
              width: 0;
            }

            .tab-chat-line {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              margin-bottom: 20px;

              .tab-chat-date {
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
                color: #FFFFFF;
                font-size: 14px;
              }

              .tab-chat-info {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;

                .tab-chat-news {
                  max-width: 718px;
                  padding: 10px 7px;
                  background-color: rgba(4, 180, 208, .48);
                  color: #fff;
                  font-size: 16px;
                }

                .tab-chat-img {
                  width: 30px;
                  height: 30px;
                  margin: 0 10px;
                }
              }

              .tab-chat-info1 {
                flex-direction: row;
              }
            }
          }
        }
      }

      .tab-info {
        width: 100%;
        height: calc(100% - 100px);
        margin-top: 20px;
        padding: 9px 40px;
        box-sizing: border-box;

        .tab-info-line {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          color: #fff;
          font-size: 18px;

          .tab-info-item {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
