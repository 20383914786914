<template>
  <div class="update">
    <div class="update-box">
      <div class="update-head">
        <div class="update-text"></div>
        <div class="update-text">
          <span>编辑</span>
        </div>
        <div class="update-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="update-content">
        <div class="update-item">
          <div class="update-key">
            <span>角色</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>描述</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-tree">
          <div class="update-key">
            <span>描述</span>
          </div>
          <div class="update-value">
            <el-tree :data="data" show-checkbox node-key="id" :default-expanded-keys="[1]" :default-checked-keys="[5]"
            :props="defaultProps">
          </el-tree>
          </div>

        </div>
      </div>
      <div class="update-footer">
        <el-button class="gray" @click="close">取消</el-button>
        <el-button @click="close">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: '',
      input: '',
      data: [{
        id: 1,
        label: 'root',
        children: [
          {
            id: 11,
            label: '用户管理',
            children: [
              {
                id: 111,
                label: '用户查询',
                children: [
                  {
                    id: 1111,
                    label: '查询'
                  },
                  {
                    id: 1112,
                    label: '新增'
                  }
                ]
              },
              {
                id: 112,
                label: '修改密码'
              }
            ]
          },
          {
            id: 12,
            label: '系统设置',
            children: [
              {
                id: 121,
                label: '菜单',
                children: [
                  {
                    id: 1211,
                    label: '查询'
                  },
                  {
                    id: 1212,
                    label: '新增'
                  },
                  {
                    id: 1212,
                    label: '删除'
                  }
                ]
              },
              {
                id: 122,
                label: '角色',
                children: [
                  {
                    id: 1211,
                    label: '查询'
                  },
                  {
                    id: 1212,
                    label: '新增'
                  },
                  {
                    id: 1212,
                    label: '删除'
                  }
                ]
              }
            ]
          },
          {
            id: 13,
            label: '文件管理',
            children: [
              {
                id: 131,
                label: '查询'
              },
              {
                id: 132,
                label: '删除'
              }
            ]
          }
        ]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);

  .update-box {
    overflow: hidden;
    width: 50%;
    height: 90%;
    background-color: #fff;
    border-radius: 6px;

    .update-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      padding: 0 21px;
      background-color: #006EFF;
      box-sizing: border-box;

      .update-text {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }

      .update-close {
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
    }

    .update-content {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: calc(100% - 115px);
      padding: 0 30px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .update-item {
        display: flex;
        align-items: center;
        width: 100%;

        .update-key {
          width: 90px;
          color: #17212B;
          font-size: 18px;
        }

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }
      }

      .update-tree {
        display: flex;
        width: 100%;
        height: calc(100% - 160px);

        .update-key {
          width: 90px;
          color: #17212B;
          font-size: 18px;
        }

        .update-value {
          overflow-y: scroll;
          width: 275px;
          height: 100%;
          border: 1px solid #707070;
          box-sizing: border-box;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }

    .update-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;

      .el-button {
        margin-right: 30px;
        padding: 5px 35px;
        background-color: #006EFF;
        border-radius: 6px;
        color: #fff;
        font-size: 22px;
      }

      .gray {
        background-color: #999999;
      }
    }
  }
}
</style>
