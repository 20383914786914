<template>
  <div class="info">
    <div class="scroll">
      <el-form>
        <div class="info-title">
          <span>员工基本信息</span>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">员工姓名</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">身份证</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">性别</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">出生日期</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">民族</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">婚姻状态</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">籍贯</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">户籍地址</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">家庭地址</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">联系电话</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>紧急联系人电话</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>员工头像</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">文化程度</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">政治面貌</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职称级别</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职称</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职务</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">专业类型</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">薪资范围</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">其他待遇</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">其他待遇</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>合同类型</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>合同编号</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span>合同开始时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span>合同结束时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span>入职时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span class="key">人员类型</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">工作岗位</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item item1">
            <el-button type="primary" @click="openGroupDivision">小组划分</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button type="primary" class="color">保存</el-button>
      <el-button>返回</el-button>
    </div>
    <GroupDivision v-if="showGroupDivision" @close="closeGroupDivision"></GroupDivision>
  </div>
</template>

<script>
import GroupDivision from './groupDivision.vue'
export default {
  components: {
    GroupDivision
  },
  data () {
    return {
      imageUrl: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      showGroupDivision: false
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openGroupDivision () {
      this.showGroupDivision = true
    },
    closeGroupDivision () {
      this.showGroupDivision = false
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: relative;
  width: 100%;
  height: 100%;

  .info-title {
    width: 100%;
    padding-left: 18px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }

  .scroll {
    width: 100%;
    height: calc(100% - 50px);
  }

  .info-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #000;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;

      span {
        margin-right: 10px;
      }

      .key {
        width: 80px;
        text-align-last: justify;
      }

      .el-input {
        width: 170px;
        height: 38px;
      }

      .el-input-width {
        width: 248px;
      }
    }

    .item1 {
      width: 100%;
    }
  }

  /deep/.avatar-uploader .el-upload {
    border-radius: .075rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fff;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 33px;
    box-sizing: border-box;
  }
}
</style>
