<template>
  <div class="enter">
    <div class="enter-left">
      <div class="head" @click="close">
        <span>在线用户</span>
      </div>
      <div class="content">
        <div class="green"></div>
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
        </el-input>
        <div class="scroll">
          <div class="item">
            <div class="img"></div>
            <div class="info">
              <span class="span">智慧正凯（主持人、我）</span>
              <span>正凯科技</span>
            </div>
            <img class="video" src="@/assets/img/novideo.png" alt="">
          </div>
          <div class="item active">
            <div class="img"></div>
            <div class="info">
              <span class="span">小程序用户xv6szBgQ</span>
              <span>(访客)</span>
            </div>
            <div class="position">
              <el-button size="mini">静音</el-button>
              <el-button size="mini">
                <span>静音</span>
                <span class="el-icon-caret-bottom"></span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="enter-right">
      <div class="title">
        <div class="div1"></div>
        <div class="div2"></div>
        <el-select v-model="value" placeholder="请选择" size="small">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="screen">
          <span class="el-icon-full-screen"></span>
        </div>
        <div class="div2"></div>
        <div class="div1"></div>
      </div>
      <div class="content">
        <div class="video">
          <div class="user" id="local-stream"></div>
          <div class="doctor" id="remote-stream"></div>
          <div class="operate">
            <img class="sound" src="@/assets/img/smartConsultation/sound.png">
            <img class="hang-up" src="@/assets/img/smartConsultation/hang-up.png">
          </div>
        </div>
        <div class="news">
          <div class="doctor-info">
            <div class="doctor-info-title">
              <span>医生资料</span>
            </div>
            <div class="date">
              <span>开始时间：2019-09-12 12：30</span>
              <span>有效期至：2019-09-12 12：30</span>
            </div>
            <div class="line">
              <span>病情描述：近几个月睡眠很不好，失眠多梦，盗汗，早上醒来感觉浑上班也没有精神，整个人状态都不好了，吃了一身疲累.些西药，感觉效果也不明显，不知道怎么办些西药，感觉效果也不明显，不知道怎么办</span>
            </div>
            <div class="line">
              <span>过敏史：有5年的过敏性皇炎历史特别害怕花粉、灰尘医类的逢季节交替时比较明明显流鼻涕打喷嚏不间断，一直靠皇炎喷剂维持，没有做过手术</span>
            </div>
            <div class="line">
              <span>患者处方：请根据患者病情描述和问诊单反馈.，为患者开出诊断建议方</span>
            </div>
            <div class="btns">
              <el-button>在线开方</el-button>
              <el-button>健康档案</el-button>
              <el-button>辅助监测</el-button>
              <el-button>会诊</el-button>
            </div>
          </div>
          <div class="sort">
            <div class="item" v-for="(item, index) in list" :key="index">
              <img :src="item.url" class="img">
              <div class="sort-info">
                <span class="label">{{ item.label }}</span>
                <span>{{ item.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <el-button @click="openStartVideo">开启视频</el-button>
        <el-button @click="startMeet">邀请</el-button>
        <el-button>管理成员</el-button>
        <el-button>录制</el-button>
        <el-button>应用</el-button>
        <el-button>设置</el-button>
      </div>
    </div>
    <StartVideo v-if="showStartVideo" @close="closeStartVideo"></StartVideo>
  </div>
</template>

<script>
import StartVideo from './startVideo.vue'
import TRTCCalling from 'trtc-calling-js'
export default {
  components: {
    StartVideo
  },
  data () {
    return {
      list: Object.freeze([
        { label: '心率', url: require('@/assets/img/smartConsultation/item0.png'), value: '75次/分' },
        { label: '血压', url: require('@/assets/img/smartConsultation/item1.png'), value: '75/75mmHg' },
        { label: '血氧', url: require('@/assets/img/smartConsultation/item2.png'), value: '96.2%SpO2' },
        { label: '血糖', url: require('@/assets/img/smartConsultation/item3.png'), value: '8.1mmol/L' },
        { label: '体温', url: require('@/assets/img/smartConsultation/item4.png'), value: '37℃' },
        { label: '尿糖', url: require('@/assets/img/smartConsultation/item5.png'), value: '131mg/dl' },
        { label: '尿酸', url: require('@/assets/img/smartConsultation/item6.png'), value: '210umol/L' },
        { label: '胆固醇', url: require('@/assets/img/smartConsultation/item7.png'), value: '8.1mmol/L' },
        { label: '心电图', url: require('@/assets/img/smartConsultation/item8.png'), value: '11-25 8:10' },
        { label: '尿常规', url: require('@/assets/img/smartConsultation/item9.png'), value: '210umol/L' },
        { label: '血脂', url: require('@/assets/img/smartConsultation/item10.png'), value: '8.1mmol/L' },
        { label: '更多检测', url: require('@/assets/img/smartConsultation/item11.png'), value: '11-25 8:10' }
      ]),
      options: [{
        value: '1',
        label: '1'
      }],
      value: '会诊视图',
      showStartVideo: false,

      trtcCalling: null
    }
  },
  created () {
    this.initVideo()
  },
  beforeDestroy () {
    this.loginOut()
  },
  methods: {
    initVideo () {
      const options = {
        SDKAppID: 1400478197, tim: null
      }
      this.trtcCalling = new TRTCCalling(options)
      this.trtcLogin()
      this.videoWatch()
    },
    videoWatch () {
      this.trtcCalling.on(TRTCCalling.EVENT.INVITED, () => {
        // 收到视频通话的邀请
        this.connectMeet()
      })
      this.trtcCalling.on(TRTCCalling.EVENT.USER_ACCEPT, () => {
        console.log('对方接听了您发出的通话邀请')
        // 对方接听了您发出的通话邀请
        this.showMeetRemote()
      })
      this.trtcCalling.on(TRTCCalling.EVENT.REJECT, () => {
        // 对方拒绝了您发出的通话邀请
      })
      this.trtcCalling.on(TRTCCalling.EVENT.CALLING_END, () => {
        // 通话结束
      })
      this.trtcCalling.on(TRTCCalling.EVENT.USER_ENTER, () => {
        // 有用户同意进入通话
        console.log('-----------------------------------------')
        console.log('有用户同意进入通话')
        this.showMeetRemote()
      })
      this.trtcCalling.on(TRTCCalling.NO_RESP, () => {
        // 无人应答
      })
    },
    // 1.登录
    trtcLogin () {
      this.trtcCalling.login({
        userID: '30203000',
        userSig: 'eJyrVgrxCdZLrSjILEpVsjI0NTU1MjAw0AGLlqUWKVkpGekZKEH4xSnZiQUFmSlAdSYGBibmFoaW5hCZzJTUvJLMtEywBmMDIwNjoCEwXZnpQEHP1FKDtOT0oBKfpKx0T-c8bzOvDJ*ClMjwoGyTFAsTE78Cx6yw0mz-TFcDW6jGksxckJPMDQyNTI3MDI1rAWinMRA_'

      }).then(res => {
        console.log('登录成功')
        // this.videoWatch()
      }).catch(error => {
        console.warn('login error:', error)
      })
    },
    // 2.拨打电话
    startMeet () {
      const offlinePushInfo = {
        title: '',
        description: '您有一个通话请求',
        extension: ''
      }
      this.trtcCalling.call({
        userID: '30101000', // 被呼叫用户 ID
        type: 2, // 通话类型，0-未知， 1-语音通话，2-视频通话
        offlinePushInfo
      }).then(res => {
        this.showMeetLocal()
      }).catch(error => {
        console.warn('拨打 error:', error)
      })
    },
    // 收到电话
    connectMeet () {
      console.log('收到电话')
    },
    // 接听通话
    accept () {
      this.trtcCalling.accept().then(res => {
        this.showMeetLocal()
        this.showMeetRemote()
      }).catch(error => {
        console.warn('接通 error:', error)
      })
    },
    // 3.显示本地视频画面
    showMeetLocal () {
      this.trtcCalling.startLocalView({
        userID: '30203000', // 本地用户的 userID
        videoViewDomID: 'local-stream' // 用于显示摄像头画面的 DOM 节点
      }).then(res => {
      }).catch(error => {
        console.warn('startLocalView error:', error)
      })
    },
    // 4.显示远端视频画面
    showMeetRemote () {
      this.trtcCalling.startRemoteView({
        userID: '30101000', // 远端用户 ID
        videoViewDomID: 'remote-stream' // 用于显示对方视频画面的 DOM 节点
      }).then(res => {
      }).catch(error => {
        console.log('-----------------------------------------')
        console.warn('startRemoteView error:', error)
      })
    },
    // 5.取消通话
    handleCancelCallUser () {
      this.trtcCalling.hangup().then(() => {
        console.log('已取消通话')
      }).catch(error => {
        console.warn('hangup error:', error)
      })
    },
    // 6.退出登录
    loginOut () {
      this.trtcCalling.logout().then(res => {
        console.log('退出登录')
      }).catch(error => {
        console.warn('接通 error:', error)
      })
    },
    openStartVideo () {
      this.showStartVideo = true
    },
    closeStartVideo () {
      this.showStartVideo = false
    },
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.enter {
  display: flex;
  width: 100%;
  height: 100%;
  // padding: 10px 0;
  background-color: #071224;
  box-sizing: border-box;

  .enter-left {
    width: 270px;
    height: 100%;
    margin-right: 10px;
    background-color: #081C38;

    .head {
      width: 100%;
      height: 35px;
      line-height: 35px;
      margin-bottom: 8px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../../../../views/zhyl/img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 50px);
      padding-top: 18px;
      background-color: rgba(29, 64, 112, .33);
      border-radius: 6px;
      box-sizing: border-box;

      .green {
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 88;
        width: 60px;
        height: 2px;
        background-color: #44D7B6;
        border-radius: 2px;
      }

      .el-input {
        width: 95%;

        /deep/.el-input__inner {
          background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
          border-radius: 6px;
          font-size: 18px;
          border: 1px solid #1F72D3;
        }

        /deep/.el-input__inner::placeholder {
          color: #fff;
        }

        /deep/.el-input__suffix {
          color: #fff;
          font-size: 20px;
        }
      }

      .scroll {
        overflow: scroll;
        width: 100%;
        height: calc(100% - 50px);
        margin-top: 10px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100px;
          margin-bottom: 17px;
          padding: 0 10px;
          border-bottom: 1px solid #FFFFFF;
          box-sizing: border-box;

          .img {
            width: 38px;
            height: 38px;
            background-color: #fff;
            border-radius: 50%;
          }

          .info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 80px);
            margin-left: 5px;
            color: #01EEFD;
            font-size: 14px;

            .span {
              margin-bottom: 10px;
            }
          }

          .video {
            width: 32px;
            height: 28px;
          }
        }

        .active {
          position: relative;
          background: rgba(1, 118, 204, 0.302);
          border-bottom: none;

          .info {
            width: calc(100% - 50px);
          }

          .position {
            position: absolute;
            right: 10px;
            bottom: 10px;

            .el-button {
              border: none;
              background-color: #0176CC;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .enter-right {
    width: calc(100% - 280px);
    height: 100%;
    padding: 20px 40px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 70px;

      .div1 {
        width: 3px;
        height: 30px;
        background-color: #fff;
        border-radius: 1px;
      }

      .div2 {
        width: 23px;
        height: 30px;
        margin-left: 1px;
        margin-right: 1px;
        border: 2px solid #fff;
        border-radius: 2px;
        box-sizing: border-box;
      }

      .el-select {
        width: 116px;
        margin-left: 7px;
        margin-right: 28px;
      }

      .screen {
        margin-right: 12px;
        color: #fff;
        font-size: 34px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - 170px);

      .video {
        width: 637px;
        height: 100%;

        .user,
        .doctor {
          width: 100%;
          height: 280px;
          background-color: #707070;
        }

        .doctor {
          margin-top: 10px;
          background-color: #333333;
        }

        .operate {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          height: 60px;
          margin-top: 10px;

          .sound {
            width: 24px;
            height: 40px;
            cursor: pointer;
          }

          .hang-up {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
        }
      }

      .news {
        width: calc(100% - 655px);
        height: 100%;
        margin-left: 18px;

        .doctor-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 270px;
          padding: 12px;
          background-color: #fff;
          border-radius: 8px;
          box-sizing: border-box;

          .doctor-info-title {
            width: 100%;
            text-align: center;
            color: #2A374D;
            font-size: 20px;
            font-weight: 600;
          }

          .date {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            color: #409EFF;
            font-size: 18px;
            font-weight: 600;
          }

          .line {
            width: 100%;
            color: #14487D;
            font-size: 18px;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .el-button {
              background-color: #409EFF;
              color: #fff;
            }
          }
        }

        .sort {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          height: 340px;
          margin-top: 10px;

          .item {
            display: flex;
            align-items: center;
            width: 32%;
            height: 75px;
            padding: 20px;
            background-color: #fff;
            border-radius: 8px;
            box-sizing: border-box;

            .img {
              width: 41px;
              height: 41px;
            }

            .sort-info {
              display: flex;
              flex-direction: column;
              margin-left: 18px;
              color: #A1A6BB;
              font-size: 16px;

              .label {
                color: #2A374D;
              }
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;

      .el-button {
        margin: 0 10px;
        border: none;
        background-color: #0176CC;
        color: #fff;
      }
    }
  }
}
</style>
