<template>
  <div class="referral">
    <div class="head">
      <el-button @click="tabClick(index)" class="color" :class="{ blue: tabIndex === index }"
        v-for="(item, index) in heads" :key="index">{{ item }}</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <span class="span">交易时间</span>
        <el-date-picker type="date" placeholder="请选择交易时间">
        </el-date-picker>
      </div>
      <div class="item">
        <span class="span">流水号</span>
        <el-date-picker type="date" placeholder="请选择交易时间">
        </el-date-picker>
      </div>
      <el-button class="blue width">查询</el-button>
      <el-button class="width">重置</el-button>
    </div>
    <div class="swich" v-show="tabIndex === 0">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="流水号">
        </el-table-column>
        <el-table-column prop="date" label="用户">
        </el-table-column>
        <el-table-column prop="date" label="交易金额">
        </el-table-column>
        <el-table-column prop="type" label="来源">
        </el-table-column>
        <el-table-column prop="type" label="支付方式">
        </el-table-column>
        <el-table-column prop="type" label="交易状态">
        </el-table-column>
        <el-table-column prop="type" label="交易时间">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 1">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="流水号">
        </el-table-column>
        <el-table-column prop="date" label="用户">
        </el-table-column>
        <el-table-column prop="date" label="交易金额">
        </el-table-column>
        <el-table-column prop="type" label="来源">
        </el-table-column>
        <el-table-column prop="type" label="支付方式">
        </el-table-column>
        <el-table-column prop="type" label="交易状态">
        </el-table-column>
        <el-table-column prop="type" label="交易时间">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 2">
      <div class="tab">
      <div class="tab-item" :class="{ 'tab-active': index == 0 }" v-for="(item, index) in tabs" :key="index">
        <span>{{ item }}</span>
      </div>
    </div>
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="流水号">
        </el-table-column>
        <el-table-column prop="date" label="用户">
        </el-table-column>
        <el-table-column prop="date" label="交易金额">
        </el-table-column>
        <el-table-column prop="type" label="来源">
        </el-table-column>
        <el-table-column prop="type" label="支付方式">
        </el-table-column>
        <el-table-column prop="type" label="审核状态">
        </el-table-column>
        <el-table-column prop="type" label="审核时间">
        </el-table-column>
        <el-table-column prop="type" label="交易时间">
        </el-table-column>
        <el-table-column label="操作" width="120">
          <!-- slot-scope="scope" -->
          <template >
            <div class="flex">
              <span>通过</span>
              <span>驳回</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 3">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="流水号">
        </el-table-column>
        <el-table-column prop="date" label="用户">
        </el-table-column>
        <el-table-column prop="date" label="退款金额">
        </el-table-column>
        <el-table-column prop="type" label="来源">
        </el-table-column>
        <el-table-column prop="type" label="支付方式">
        </el-table-column>
        <el-table-column prop="type" label="退款状态">
        </el-table-column>
        <el-table-column prop="type" label="退款时间">
        </el-table-column>
        <el-table-column prop="type" label="交易时间">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      heads: ['问诊订单', '处方订单', '退款审核', '退款明细'],
      tabs: Object.freeze(['全部', '待发货', '待发货', '已完成']),
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showDetails: false,
      showConsultationSettings: false,
      value1: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openOrderDetails () {
      this.showOrderDetails = true
    },
    closeOrderDetails () {
      this.showOrderDetails = false
    },
    openDetails () {
      this.showDetails = true
    },
    closeDetails () {
      this.showDetails = false
    },
    openConsultationSettings () {
      this.showConsultationSettings = true
    },
    closeConsultationSettings () {
      this.showConsultationSettings = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head,
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-select {
      width: 170px;
      background-color: #fff;
    }

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      background-color: #284867;
      margin-right: 20px;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .blue {
      background-color: #01EEFD;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;
      border-radius: 6px;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 200px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    height: 80px;

    .tab-item {
      width: 80px;
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      text-align: center;
      background-color: #334253;
      color: #fff;
      font-size: 14px;
    }

    .tab-active {
      background: linear-gradient(102deg, #0172C6 0%, #02467C 100%);
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  ::v-deep.el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  ::v-deep.el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  ::v-deep.el-switch__label--right {
    z-index: 1;
    right: 10px;
  }

  /*关闭时文字位置设置*/
  ::v-deep.el-switch__label--left {
    z-index: 1;
    left: 10px;
  }

  /*显示文字*/
  ::v-deep.el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  ::v-deep.el-switch .el-switch__core,
  ::v-deep.el-switch .el-switch__label {
    width: 60px !important;
  }
}
</style>
