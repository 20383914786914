<template>
  <div class="children">
    <div class="children-title">
      <span>新生儿家庭访视记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>性别</span>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span>出生日期</span>
        </div>
        <div class="children-td children-flex6 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>身份证号</span>
        </div>
        <div class="children-td children-flex3">
          <span>体检日期</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span>家庭住址</span>
        </div>
        <div class="children-td children-flex6 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>父亲</span>
        </div>
        <div class="children-td children-flex1">
          <span>姓名：</span>
        </div>
        <div class="children-td children-flex2">
          <span>职业：</span>
        </div>
        <div class="children-td children-flex4">
          <span>体检日期</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>母亲</span>
        </div>
        <div class="children-td children-flex1">
          <span>姓名：</span>
        </div>
        <div class="children-td children-flex2">
          <span>职业：</span>
        </div>
        <div class="children-td children-flex4">
          <span>体检日期</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>出生孕周</span>
          <span class="input"></span>
          <span>周</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <span>母亲妊娠期患病情况</span>
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">糖尿病</el-checkbox>
            <el-checkbox label="2">妊娠期高血压</el-checkbox>
            <el-checkbox label="3">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>助产机构名称：</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <span>出生情况：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>新生儿窒息：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <span>畸形：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>新生儿听力筛查：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">通过</el-radio>
            <el-radio label="1">未通过</el-radio>
            <el-radio label="2">未筛查</el-radio>
            <el-radio label="3">不详</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>新生儿疾病筛查：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未进行</el-radio>
            <el-radio label="1">检查均阴性</el-radio>
            <el-radio label="2">甲低</el-radio>
            <el-radio label="3">苯丙酮尿症</el-radio>
            <el-radio label="4">其他遗传代谢病</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex4">
          <span>新生儿出生体重：</span>
          <span class="input"></span>
          <span>kg</span>
        </div>

        <div class="children-td children-flex4">
          <span>目前体重：</span>
          <span class="input"></span>
          <span>kg</span>
        </div>

        <div class="children-td children-flex4 children-border-right-none">
          <span>出生身长：</span>
          <span class="input"></span>
          <span>cm</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex5">
          <span>喂养方式:</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">纯母乳</el-radio>
            <el-radio label="1">混合</el-radio>
            <el-radio label="2">人工</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex3">
          <span>吃奶量：</span>
          <span class="input"></span>
          <span>mL/次</span>
        </div>

        <div class="children-td children-flex4 children-border-right-none">
          <span>吃奶次数：</span>
          <span class="input"></span>
          <span>次/日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex4">
          <span>呕吐: </span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex4">
          <span>大便: </span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">糊状</el-radio>
            <el-radio label="1">稀</el-radio>
            <el-radio label="2">其他</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex4 children-border-right-none">
          <span>大便次数：</span>
          <span class="input"></span>
          <span>次/日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex4">
          <span>体温: </span>
          <span class="input"></span>
          <span>℃</span>
        </div>

        <div class="children-td children-flex4">
          <span>心率: </span>
          <span class="input"></span>
          <span>次/分钟</span>
        </div>

        <div class="children-td children-flex4 children-border-right-none">
          <span>呼吸频率：</span>
          <span class="input"></span>
          <span>次/fen</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex4">
          <span>面色：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">红润</el-radio>
            <el-radio label="1">黄染</el-radio>
            <el-radio label="2">其他</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex8 children-border-right-none">
          <span>黄疸部位：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">面部</el-radio>
            <el-radio label="2">躯干</el-radio>
            <el-radio label="3">四肢</el-radio>
            <el-radio label="4">手足</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>前囟：</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">cm</span>
          <span>x</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">cm</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">膨隆</el-radio>
            <el-radio label="2">凹陷 </el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>眼睛：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>四肢活动度：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>耳外观：</span>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>颈部包块：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">湿疹</el-radio>
            <el-radio label="2">糜烂 </el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>鼻：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>皮肤：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>口腔：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>肛门：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>心肺听诊：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>胸部：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>腹部触诊：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>

        <div class="children-td children-flex1 children-border-right-none">
          <span>脊柱：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>外生殖器：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>脐带：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未脱</el-radio>
            <el-radio label="1">脱落</el-radio>
            <el-radio label="2">脐部有渗出 </el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>转诊建议：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1">
          <span>原因：</span>
        </div>
        <div class="children-td children-flex1 children-border-right-none">
          <span>机构及科室：</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-border-right-none">
          <span>指导：</span>
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">喂养指导</el-checkbox>
            <el-checkbox label="1">发育指导</el-checkbox>
            <el-checkbox label="2">防病指导</el-checkbox>
            <el-checkbox label="3">预防伤害指导</el-checkbox>
            <el-checkbox label="4">口腔保健指导</el-checkbox>
            <el-checkbox label="5">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>本次访视日期：</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">年</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">月</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">日</span>
        </div>
        <div class="children-td children-flex1 children-border-right-none">
          <span>下次随访地点：</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>下次访视日期：</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">年</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">月</span>
          <el-input class="input"></el-input>
          <span class="margin-left-none">日</span>
        </div>
        <div class="children-td children-flex1 children-border-right-none">
          <span>随访医生签名：</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }
    }

    .margin-left {
      margin-left: 14px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .input {
      width: 50px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}</style>
