<template>
  <div class="referral">
    <div class="head">
      <el-button @click="openAddReferral">新增</el-button>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="name" label="机构名称">
      </el-table-column>
      <el-table-column prop="date" label="转诊时间">
      </el-table-column>
      <el-table-column label="操作" width="200">
        <!-- slot-scope="scope" -->
        <template>
          <div class="flex">
            <span @click="openSeeReferral">查看详情</span>
            <span @click="openAddReferral">编辑</span>
            <span>删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <AddReferral v-if="showAddReferral" @close="closeAddReferral"></AddReferral>
    <SeeReferral v-if="showSeeReferral" @close="closeSeeReferral"></SeeReferral>
  </div>
</template>

<script>
import AddReferral from './addReferral.vue'
import SeeReferral from './seeReferral.vue'
export default {
  components: {
    AddReferral,
    SeeReferral
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }],
      showAddReferral: false,
      showSeeReferral: false
    }
  },
  methods: {
    openAddReferral () {
      this.showAddReferral = true
    },
    closeAddReferral () {
      this.showAddReferral = false
    },
    openSeeReferral () {
      this.showSeeReferral = true
    },
    closeSeeReferral () {
      this.showSeeReferral = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 10px 37px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .el-button {
      width: 78px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      background-color: #01EEFD;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;
    }

    // /deep/.el-input__inner {
    //   background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    //   border: 1px solid #1F72D3;
    //   color: #fff !important;
    //   font-size: 18px;
    // }

    // /deep/ .el-input__suffix {
    //   color: #fff;
    //   font-size: 18px;
    // }
  }

  .table {
    width: 100%;
    height: calc(100% - 200px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    // ::v-deep.el-pagination__total,
    // ::v-deep.el-pagination__jump {
    //   color: #01EEFD;

    //   .el-input__inner {
    //     color: #fff;
    //     background-color: transparent;
    //   }
    // }

    // ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    //   background-color: #01EEFD;
    // }
  }
}
</style>
