<template>
  <div class="allocation">
    <div class="allocation-box">
      <div class="allocation-head">
        <div class="allocation-text">
          <span>设置角色人员</span>
        </div>
        <div @click="close" class="allocation-close">
          <span class="el-icon-error"></span>
        </div>
      </div>
      <div class="allocation-content">
        <el-transfer filterable :filter-method="filterMethod" filter-placeholder="请输入搜索内容" v-model="value" :titles="['人员列表', '已设置的人员']" :data="list">
          <span slot-scope="{ option }" class="transfer">
            <span>{{ option.label }}</span>
            <span>{{ option.text }}</span>
          </span>
        </el-transfer>
      </div>
      <div class="allocation-footer">
        <el-button type="warning">取消</el-button>
        <el-button type="primary">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { label: '测试1', key: 0, pinyin: '测试1', text: 'zzzz1' },
        { label: '测试2', key: 1, pinyin: '测试2', text: 'zzzz2' }
      ],
      value: [],
      filterMethod (query, item) {
        return item.pinyin.indexOf(query) > -1
      }
    }
  },
  methods: {
    close () {
      this.$parent.showAllocation = false
    }
  }
}
</script>

<style lang="less" scoped>
.allocation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .allocation-box {
    width: 50%;
    height: 80%;
    background-color: #fff;
    border-radius: 10px;

    .allocation-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .allocation-text {
        font-size: 18px;
      }

      .allocation-close {
        color: red;
        font-size: 30px;
        cursor: pointer;
      }
    }

    .allocation-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - 120px);
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      .el-transfer {
        display: flex;
        width: 100%;
        height: 80%;
      }

      .transfer {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      /deep/.el-checkbox {
        margin-right: 10px !important;
      }

      /deep/.el-transfer__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 100%;
      }

      /deep/.el-button+.el-button {
        margin-left: 0;
      }

      /deep/.el-transfer-panel {
        width: 40%;
        height: 100%;
      }

      /deep/.el-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .allocation-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 70px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  }
}
</style>
