<template>
  <div class="table">
    <el-table :data="tableData" height="100%" border lazy row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="名称" width="180">
      </el-table-column>
      <el-table-column prop="id" label="id">
      </el-table-column>
      <el-table-column prop="href" label="href">
      </el-table-column>
      <el-table-column prop="permission" label="permission">
      </el-table-column>
      <el-table-column prop="sort" label="sort">
      </el-table-column>
      <el-table-column label="操作" width="100">
        <div class="flex">
          <span class="span" @click="openUpdate">编辑</span>
          <span class="span">删除</span>
        </div>
      </el-table-column>
    </el-table>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      tableData: [{
        id: 1,
        name: '用户管理',
        children: [{
          id: 11,
          name: '新增',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 12,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 13,
          name: '修改密码',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 2,
        name: '系统设置',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: '21'
        }]
      }, {
        id: 3,
        name: '文件管理',
        children: [{
          id: 31,
          name: '数据源监控',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 32,
          name: '接口swagger ',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 33,
          name: '代码生成',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 4,
        name: '公告管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 41,
          name: '日志查询',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 5,
        name: '邮件管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: '51'
        }]
      }, {
        id: 6,
        name: '定时任务管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: '61'
        }]
      }, {
        id: 7,
        name: 'excel 管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: '71'
        }]
      }, {
        id: 8,
        name: '字典管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 81,
          name: 'app版本控制',
          href: '',
          permission: '',
          sort: ''
        }]
      }],
      showUpdate: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  height: 100%;
  background-color: #fff;

  /deep/ .el-table__cell {
    text-align: center;
  }

  /deep/ .el-table__row .el-table__cell:first-child {
    text-align: left;
  }

  .flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    color: #399BFB;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
