<template>
  <div class="details">
    <div class="details-title">
      <span>订单详情</span>
    </div>
    <div class="details-box">
      <div class="line">
        <span>就诊人信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>患者姓名：王爱飞</span>
        </div>
        <div class="item">
          <span>性别：男</span>
        </div>
        <div class="item">
          <span>年龄：42</span>
        </div>
        <div class="item">
          <span>联系方式：13011111111</span>
        </div>
        <div class="item item1">
          <span>病情描述：最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象</span>
        </div>
        <div class="item item1">
          <span>病历资料：</span>
        </div>
      </div>
      <div class="line">
        <span>医生信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>医生：张三丰</span>
        </div>
        <div class="item">
          <span>科室：内分泌科</span>
        </div>
        <div class="item">
          <span>职称：主任医师</span>
        </div>
      </div>
      <div class="line">
        <span>订单信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>订单编号：20200408121324  </span>
        </div>
        <div class="item">
          <span>问诊方式：图文问诊</span>
        </div>
        <div class="item">
          <span>状态：待支付</span>
        </div>
        <div class="item">
          <span>应付金额：￥40</span>
        </div>
        <div class="item">
          <span>实际支付：￥30</span>
        </div>
        <div class="item item2">
          <span>创建时间：2020-03-04  11：08：18</span>
        </div>
      </div>
    </div>
    <div class="return" @click="returnPage">
      <span>返回</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-title {
    color: #01EEFD;
    font-size: 20px;
  }

  .details-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .line {
      height: 50px;
      line-height: 50px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        width: 25%;
        margin-top: 20px;
        color: #01EEFD;
        font-size: 16px;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 75%;
      }
    }
  }
  .return {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #33465C;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
