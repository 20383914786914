<template>
  <div class="popup">
    <div class="head">
      <span>查看详情</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="scroll">
      <el-form>
        <div class="title">
          <span>转诊信息</span>
        </div>
        <div class="item">
          <span class="key">患者姓名：</span>
          <span>王爱飞</span>
        </div>
        <div class="item">
          <span class="key">性别</span>
          <span>女</span>
        </div>
        <div class="item">
          <span class="key">年龄</span>
          <span>42</span>
        </div>
        <div class="item">
          <span class="key">档案编号</span>
          <span>002</span>
        </div>
        <div class="item">
          <span class="key">家庭住址</span>
          <span>北京市XXX区XXX路XXX号</span>
        </div>
        <div class="item">
          <span class="key">联系方式：</span>
          <span>13011111111</span>
        </div>
         <div class="item">
          <span class="key">转入单位：</span>
          <span>北京市朝阳医院</span>
        </div>
        <div class="item">
          <span class="key">科室：</span>
          <span>心内科</span>
        </div>
        <div class="item">
          <span class="key">接诊医生：</span>
          <span>李四</span>
        </div>
        <div class="item">
          <span class="key">转诊时间</span>
          <span>2020-03-03</span>
        </div>
        <div class="item">
          <span class="key">接诊医生</span>
          <span>王五</span>
        </div>
        <div class="item item1">
          <span class="key">转出单位：</span>
          <span>北京市六里屯社区医院</span>
        </div>
        <div class="title margin">
          <span>疾病信息</span>
        </div>
        <div class="title1 margin">
          <span>主要现病史（转出原因）</span>
        </div>
        <div class="title1 margin">
          <span>主要既往史：高血压，心脏病</span>
        </div>
        <div class="title1 margin">
          <span>接诊医生：李四</span>
        </div>
        <div class="title1 margin">
          <span>转诊时间：2020-03-03</span>
        </div>
        <div class="title1 margin">
          <span>转诊医生：王五</span>
        </div>
        <div class="title1 margin">
          <span>高血压伴有心脏病，长时间得不到改善，要求转诊</span>
        </div>
        <div class="title1 margin">
          <span>治疗经过：  从2020-02-10 开始长期服用高血压以及心脏病相关的药物治疗</span>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      date: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: .125rem .4625rem;
  padding-bottom: .25rem;
  box-sizing: border-box;
  background-color: #062138;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: .75rem;
    color: #01EEFD;
    font-size: .25rem;

    .el-input {
      width: 3.075rem;
      height: .475rem;
      border-radius: .075rem;
    }

    /deep/.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;
      color: #fff !important;
      font-size: .225rem;
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }
  }

  .scroll {
    width: 100%;
    height: calc(100% - 1.725rem);
    padding: 0 .1875rem;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: .5rem;
      padding: 0 .225rem;
      color: #01EEFD;
      font-size: .225rem;
      border-bottom: .0125rem solid #01EEFD;
      box-sizing: border-box;
    }

    .title1 {
      width: 100%;
      margin-bottom: .125rem;
      padding-left: .125rem;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .225rem;
    }

    .margin {
      margin-top: .2rem;
    }

    .el-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .item {
      width: 20%;
      margin-top: .225rem;
      color: #01EEFD;
      font-size: .2rem;

      .key {
        margin-right: .0625rem;
      }
    }

    .item1 {
      width: 80%;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
