<template>
  <div class="details">
    <div class="details-title">
      <span>详情</span>
      <el-input class="details-search" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="details-box">
      <div class="line">
        <span>详情信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>评价时间：2021-07-15 09:08:00</span>
        </div>
        <div class="item">
          <span>标题：这是一个标题

            来源：这是来源

            栏目：所属栏目

            内容：

            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor
            sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus
            et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis
            tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.

            发布时间：2021-07-09 09:09:09

            排序：1

            置顶：否</span>
        </div>
        <div class="item">
          <span>标题：这是一个标题</span>
        </div>
        <div class="item">
          <span>来源：这是来源</span>
        </div>
        <div class="item">
          <span>内容：</span>
        </div>
        <div class="item">
          <span>发布时间：</span>
        </div>
        <div class="item">
          <span>排序：1</span>
        </div>
        <div class="item">
          <span>置顶：否</span>
        </div>
      </div>
    </div>
    <div class="position">
      <div class="return" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showUpdate: false,
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #01EEFD;
    font-size: 20px;

    .details-search,
    /deep/.el-input__inner {
      width: 246px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%) !important;
      border: 1px solid #1F72D3;
      border-radius: 6px;
    }
  }

  .details-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .line {
      height: 50px;
      line-height: 50px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        width: 100%;
        margin-top: 20px;
        color: #01EEFD;
        font-size: 16px;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 75%;
      }

      .item3 {
        display: flex;
        align-items: center;
        width: 50%;
      }

      /deep/.avatar-uploader .el-upload {
        display: flex;
        align-items: center;
        margin-left: 20px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        margin-left: 20px;
        font-size: 20px;
        color: #01EEFD;
        text-align: center;
      }

      .avatar {
        width: 25px;
        height: 25px;
        display: block;
      }
    }
  }

  .position {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .return {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #33465C;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .blue {
    margin-right: 34px;
    background-color: #04B4D0;
  }
}</style>
