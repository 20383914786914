<template>
  <div class="write">
    <div class="title">
      <span>详情</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">详情</span>
          <el-input placeholder=""></el-input>
        </div>
        <div class="item">
          <span class="span">来源</span>
          <el-input placeholder=""></el-input>
        </div>
        <div class="item">
          <span class="span">排序</span>
          <el-input placeholder=""></el-input>
        </div>
        <div class="item">
          <span class="span">置顶</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </div>
        <div class="item item2">
          <span class="span">栏目</span>
          <el-select v-model="value" placeholder="请选择栏目">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">内容</span>
          <el-input type="textarea"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button size="small" class="button">发布</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1'
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 540px;
    margin-top: 10px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input,
      .el-select {
        width: 150px;
        height: 38px;
      }
    }

    .item1 {
      width: 100%;
      align-items: flex-start;
    }

    .item2 {
      width: 66.5%;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 46px;
      margin: 10px auto 0;

      .el-button {
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-textarea__inner {
    height: 203px;
    resize: none;
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  /deep/.el-radio-group {
    margin-top: 4px;
  }

  .el-checkbox,
  .el-radio {
    color: #fff;
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
