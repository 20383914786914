<template>
  <div class="tab-box">
    <div class="input">
      <el-button class="width">导出</el-button>
      <div class="add">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <div class="tab-box-item">
      <el-tree :data="data" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span class="tree-label">{{ node.label }}</span>
          <span>
            <el-button  v-if="node.label == 'A层级菜单1'" type="text" size="mini" class="tree-button"
              @click="() => append(data, node)">
              新增
            </el-button>
            <el-button  type="text" size="mini" :class="{ 'tree-button1': node.label != 'A层级菜单1' }"
              @click="() => append(data)">
              修改
            </el-button>
            <el-button type="text" size="mini" @click="() => remove(node, data)">
              删除
            </el-button>
          </span>
        </span>
        <span>新增</span>
      </el-tree>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: 10,
      data: [
        {
          id: 1,
          label: 'A层级菜单1',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        },
        {
          id: 2,
          label: 'A层级菜单1',
          children: []
        },
        {
          id: 3,
          label: 'A层级菜单1',
          children: [{
            id: 31,
            label: 'B层级菜单1'
          }, {
            id: 32,
            label: 'B层级菜单2'
          }, {
            id: 33,
            label: 'B层级菜单3'
          }]
        },
        {
          id: 4,
          label: 'A层级菜单1',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    append (data) {
      // const newChild = { id: this.id++, label: 'testtest', children: [] }
      // // console.log(newChild)
      // if (!data.children) {
      //   this.$set(this.data, 'children', [])
      // }
      // data.children.push(newChild)
    },

    remove (node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="less">
.tab-box {
  width: 100%;
  height: calc(100% - 120px);

  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .add {
      position: absolute;
      right: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #01EEFD;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
    }

    .el-button {
      width: 80px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      border: 0;
      background-color: #284867;
      margin-right: 20px;
      font-size: 18px;
      background-color: #01EEFD;
    }
  }

  .tab-box-item {
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .tab-box-title {
    height: 80px;
    line-height: 80px;
    margin-bottom: 10px;
    color: #01EEFD;
    font-size: 20px;
  }

  .el-tree {
    background-color: transparent;
    color: #01EEFD;
    font-size: 18px;

    .el-button {
      padding-top: 4px;
      padding-bottom: 4px;
      color: #01EEFD;
      font-size: 16px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;

      span {
        text-decoration: dashed;
      }
    }

    /deep/.el-tree-node__content:hover {
      background-color: transparent;
    }

    /deep/.el-tree-node:focus>.el-tree-node__content {
      background-color: transparent;
    }

    .tree-label {
      display: inline-block;
      width: 140px;
    }
  }

  .tree-button {
    margin-left: 50px;
  }

  .tree-button1 {
    margin-left: 32px;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    color: #fff;
  }
}
</style>
