<template>
  <div class="popup">
    <div class="head">
      <span>新增</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="scroll">
      <el-form>
        <div class="title">
          <span>转诊信息</span>
        </div>
        <div class="item">
          <span class="key">患者姓名</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">性别</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="key">年龄</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">档案编号</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">家庭住址</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">转入单位</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">科室</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">接诊医生</span>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <span class="key">转诊时间</span>
          <el-date-picker v-model="date" size="small" class="width1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="key">转诊医生</span>
          <el-input size="small"></el-input>
        </div>
        <div class="title margin">
          <span>疾病信息</span>
        </div>
        <div class="title1 margin">
          <span>主要现病史（转出原因）</span>
        </div>
        <el-input resize="none" type="textarea" :rows="3">
        </el-input>
        <div class="title1 margin">
          <span>主要既往史</span>
        </div>
        <el-input resize="none" type="textarea" :rows="3">
        </el-input>
        <div class="title1 margin">
          <span>治疗经过</span>
        </div>
        <el-input resize="none" type="textarea" :rows="3">
        </el-input>
      </el-form>
    </div>
    <div class="btns">
      <el-button @click="close" class="color">提交</el-button>
      <el-button @click="close">清空</el-button>
      <el-button @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      date: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: .125rem .4625rem;
  padding-bottom: .25rem;
  box-sizing: border-box;
  background-color: #062138;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: .975rem;
    margin-bottom: .25rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: .275rem;

    .el-input {
      width: 3.075rem;
      height: .475rem;
      border-radius: .075rem;
    }

    /deep/.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;
      color: #fff !important;
      font-size: .225rem;
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }
  }

  .scroll {
    width: 100%;
    height: calc(100% - 1.725rem);
    padding: 0 .1875rem;
    box-sizing: border-box;

    .title {
      width: 100%;
      color: #01EEFD;
      font-size: .25rem;
    }

    .title1 {
      width: 100%;
      margin-bottom: .125rem;
      padding-left: .125rem;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .225rem;
    }

    .margin {
      margin-top: .2rem;
    }

    .el-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .item {
      width: 20%;
      margin-top: .225rem;
      color: #01EEFD;
      font-size: .225rem;

      .key {
        margin-right: .0625rem;
      }

      .el-input {
        width: 1.625rem;
      }

      .el-radio {
        margin-right: .125rem;
        color: #fff;
        font-size: .225rem;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #fff;
      }

      /deep/.el-radio__inner {
        width: .225rem;
        height: .225rem;
        background-color: transparent;
        border-color: #FFFFFF;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        width: .225rem;
        height: .225rem;
        border-color: #01EEFD;
        background-color: #01EEFD;
      }

      /deep/.el-radio__inner::after {
        content: '✓';
        width: .225rem;
        height: .225rem;
        line-height: .225rem;
        text-align: center;
        font-size: .125rem;
        background-color: transparent;
      }
    }

    /deep/.el-textarea__inner {
      width: 90%;
      height: 1rem;
      margin-left: .25rem;
      background-color: transparent;
      border: .0125rem solid #01EEFD;
      box-sizing: border-box;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
