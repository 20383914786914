<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-05-20 17:03:12
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-17 15:54:10
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\psychosis\item1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="children">
    <div class="children-title">
      <span>严重精神障碍患者个人信息补充表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <!-- <div class="position">
        <span>产科检</span>
      </div> -->
      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>随访时间</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span>与患者关系</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>监护人住址</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span>监护人电话</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>辖区村（居）委会联系人、电话</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none"></div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>户别</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊城镇</el-radio>
            <el-radio label="1">农村</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <span>就业情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">在岗工人</el-radio>
            <el-radio label="1">在岗管理者</el-radio>
            <el-radio label="2">农民</el-radio>
            <el-radio label="3">下岗或无业</el-radio>
            <el-radio label="4">在校学生</el-radio>
            <el-radio label="5">退休</el-radio>
            <el-radio label="6">专业技术人员</el-radio>
            <el-radio label="7">其他</el-radio>
            <el-radio label="8">不详</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr2 children-border">
        <div class="children-td children-flex2">
          <span>知情同意</span>
        </div>
        <div class="children-td children-flex10 children-wrap children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">同意参加管理</el-radio>
            <el-radio label="1">不同意参加管理</el-radio>
          </el-radio-group>
          <div class="label">
            <span class="color">签字：</span>
          </div>
          <div class="label">
            <span class="color">签字时间 年 月 日</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>初次发病时间</span>
        </div>
        <div class="children-td children-flex4 children-text-center children-border-right-none">
          <span class="color">年 月 日</span>
        </div>
        <div class="children-td children-flex4 children-border-right-none">
          <span></span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <span>既往主要症状</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0" class="margin-bottom">幻觉</el-checkbox>
            <el-checkbox label="1" class="margin-bottom">交流困难</el-checkbox>
            <el-checkbox label="2" class="margin-bottom">猜疑</el-checkbox>
            <el-checkbox label="3" class="margin-bottom">喜怒无常</el-checkbox>
            <el-checkbox label="4" class="margin-bottom">行为怪异</el-checkbox>
            <el-checkbox label="5" class="margin-bottom">兴奋话多</el-checkbox>
            <el-checkbox label="6" class="margin-bottom">伤人毁物</el-checkbox>
            <el-checkbox label="7" class="margin-bottom">悲观厌世</el-checkbox>
            <el-checkbox label="8" class="margin-bottom">无故外走</el-checkbox>
            <el-checkbox label="9" class="margin-bottom">自语自笑</el-checkbox>
            <el-checkbox label="10" class="margin-bottom">孤僻懒散</el-checkbox>
            <el-checkbox label="11" class="margin-bottom">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>既往关锁情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无关锁</el-radio>
            <el-radio label="1">关锁</el-radio>
            <el-radio label="2">关锁已解除</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <div class="children-td-left children-border-right children-td-text1">
            <span>既往治疗情况</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>门诊</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>住院</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex6 children-border-right-none">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未治</el-radio>
                <el-radio label="1">间断门诊治疗</el-radio>
                <el-radio label="2">连续门诊治疗</el-radio>
              </el-radio-group>
            </div>
            <div class="children-td children-flex4 children-border-right-none">
              <span class="color">首次抗精神病药治疗时间 年 月 日</span>
            </div>
          </div>
          <div class="children-td-list">
            <span class="margin-left color">曾住精神专科医院/综合医院精神专科次</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>目前诊断情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <span class="color">诊断确诊医院确诊日期</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>最近一次治疗效果</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">临床痊愈</el-radio>
            <el-radio label="1">好转</el-radio>
            <el-radio label="2">无变化</el-radio>
            <el-radio label="3">加重</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>危险行为</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">轻度滋事次</el-checkbox>
            <el-checkbox label="1">肇事次</el-checkbox>
            <el-checkbox label="2">肇祸次</el-checkbox>
            <el-checkbox label="3">其他危害行为次</el-checkbox>
            <el-checkbox label="4">自伤次</el-checkbox>
            <el-checkbox label="5">自杀未遂次</el-checkbox>
            <el-checkbox label="6">无</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>经济状况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">贫困，在当地贫困线标准以下</el-radio>
            <el-radio label="1">非贫困</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <span>专科医生的意见(如果有请记录)</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">贫困，在当地贫困线标准以下</el-radio>
            <el-radio label="1">非贫困</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>填表日期</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span class="color">年 月 日</span>
        </div>
        <div class="children-td children-flex4 children-text-center">
          <span class="color">随访医生签名</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #fff;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        white-space: nowrap;
        color: #000;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 100%;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 70px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      // padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
