import { render, staticRenderFns } from "./OnlineConsultationService.vue?vue&type=template&id=6f014c03&scoped=true&"
import script from "./OnlineConsultationService.vue?vue&type=script&lang=js&"
export * from "./OnlineConsultationService.vue?vue&type=script&lang=js&"
import style0 from "./OnlineConsultationService.vue?vue&type=style&index=0&id=6f014c03&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f014c03",
  null
  
)

export default component.exports