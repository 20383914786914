var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"details-title"},[_c('span',[_vm._v("评价审核")]),_c('el-input',{staticClass:"details-search",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}})],1),_c('div',{staticClass:"details-box"},[_vm._m(0),_c('div',{staticClass:"list"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"item item3"},[_c('span',[_vm._v("头像")]),_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl}}),_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1)])]),_c('div',{staticClass:"position"},[_vm._m(6),_c('div',{staticClass:"return",on:{"click":_vm.returnPage}},[_c('span',[_vm._v("不通过")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("审核信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("评价时间：2021-07-15 09:08:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("用户：2038rf")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("医生：张翠山")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("职称：主任医师")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item3"},[_c('span',[_vm._v("评价内容：山供电搜富哦腐啊福哦按腐啊发发发发发")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"return blue"},[_c('span',[_vm._v("通过")])])
}]

export { render, staticRenderFns }