<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-02-24 08:45:56
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-02-26 16:43:14
 * @FilePath: \visions\src\views\zhyl\dictionary\dictionary.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="organsetup">
    <div class="tab">
      <span v-for="(item, index) in tabs" :key="index" :class="{ title: tabIndex === index }" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <Run></Run>
    </div>
  </div>
</template>

<script>
import Run from './run.vue'
export default {
  components: {
    Run
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['护工管理', '护理级别设置', '护理项目'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.organsetup {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #409EFF;
    }
  }

  .content {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
