<template>
  <div class="referral">
    <div class="head">
      <el-button @click="tabClick(index)" class="color" :class="{ blue: tabIndex === index }"
        v-for="(item, index) in heads" :key="index">{{ item }}</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <Tab1 v-if="tabIndex === 0"></Tab1>
    <Tab2 v-else-if="tabIndex === 1"></Tab2>
    <Tab3 v-else-if="tabIndex === 2"></Tab3>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3
  },
  data () {
    return {
      heads: ['互联网药房', '院内药房 ', '药品挂靠'],
      tabIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head,
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      background-color: #284867;
      margin-right: 20px;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .blue {
      background-color: #01EEFD;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;
      border-radius: 6px;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 200px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    height: 80px;

    .tab-item {
      width: 80px;
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 14px;
    }

    .tab-active {
      background: linear-gradient(102deg, #0172C6 0%, #02467C 100%);
    }
  }
}
</style>
