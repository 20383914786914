import { render, staticRenderFns } from "./tab1.vue?vue&type=template&id=b11eb56a&scoped=true&"
import script from "./tab1.vue?vue&type=script&lang=js&"
export * from "./tab1.vue?vue&type=script&lang=js&"
import style0 from "./tab1.vue?vue&type=style&index=0&id=b11eb56a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b11eb56a",
  null
  
)

export default component.exports