var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_vm._m(0),_c('div',{staticClass:"details-box"},[_vm._m(1),_c('div',{staticClass:"list"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"item item3"},[_c('span',[_vm._v("头像")]),_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl}}),_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_vm._m(7)]),_vm._m(8),_vm._m(9)]),_c('div',{staticClass:"position"},[_c('div',{staticClass:"return blue",on:{"click":_vm.openUpdate}},[_c('span',[_vm._v("修改")])]),_c('div',{staticClass:"return",on:{"click":_vm.returnPage}},[_c('span',[_vm._v("返回")])])]),(_vm.showUpdate)?_c('Update',{on:{"close":_vm.closeUpdate}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title"},[_c('span',[_vm._v("医生详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("医生信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名：王爱飞")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("手机号码：12903210311")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("科室：内分泌科")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("职称：主任医师")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item3"},[_c('span',[_vm._v("擅长：山供电搜富哦腐啊福哦按腐啊发发发发发")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item3"},[_c('span',[_vm._v("简介：山供电搜富哦腐啊福哦按腐啊发发发发发")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("问诊方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("图文问诊 ￥80")])]),_c('div',{staticClass:"item item2"},[_c('span',[_vm._v("视频问诊 ￥80")])])])
}]

export { render, staticRenderFns }