<template>
  <div class="group">
    <div class="scroll">
      <el-form>
        <div class="info-title">
          <span>员工基本信息</span>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">小组名称</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">小组组长</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <el-button type="primary">设置</el-button>
          </div>
        </div>
        <div class="info-title">
          <span>选择小组成员</span>
        </div>
        <div class="table">
          <div class="table-left">
            <div class="head">
              <span>未分组成员</span>
              <el-button size="small" type="primary">添加新护理成员</el-button>
            </div>
            <div class="no">
              <span>没有可选护理人员</span>
            </div>
            <div class="table-title">
              <span>介护组</span>
              <span>组长：张小花</span>
            </div>
            <el-tree :data="data" show-checkbox node-key="id" :props="defaultProps">
            </el-tree>
          </div>
          <div class="table-right">
            <div class="head">
              <span>选择小组护理房间</span>
            </div>
            <el-tree :data="data1" show-checkbox node-key="id" :props="defaultProps">
            </el-tree>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button size="mini" type="primary" @click="close">保存</el-button>
      <!-- <el-button >返回</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      data: [{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1'
        }, {
          id: 3,
          label: '二级 1-1'
        }, {
          id: 2,
          label: '二级 1-1'
        }]
      }],
      data1: [{
        id: 1,
        label: 'A栋',
        children: [{
          id: 4,
          label: '1楼'
        }, {
          id: 3,
          label: '2楼'
        }, {
          id: 2,
          label: '3楼'
        }]
      }, {
        id: 1,
        label: 'B栋',
        children: [{
          id: 41,
          label: '1楼'
        }, {
          id: 31,
          label: '2楼'
        }, {
          id: 21,
          label: '3楼'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.group {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .info-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 18px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .info-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      padding-left: 18px;
      box-sizing: border-box;
      color: #000;
      font-size: .225rem;

      span {
        margin-right: 10px;
      }

      .key {
        width: 80px;
        text-align-last: justify;
      }

      .el-input {
        width: 170px;
        height: 38px;
      }
    }
  }

  .table {
    display: flex;
    width: 100%;
    height: 478px;
    border: 1px solid #000;
    box-sizing: border-box;

    .table-left {
      width: calc(100% - 213px);
      height: 100%;

      .el-tree {
        width: 98%;
        margin: 20px auto;
        background-color: transparent;
        color: #000;
        border: 1px solid #000;

        /deep/.el-tree-node__content {
          background-color: transparent;
        }
      }
    }

    .table-right {
      width: 253px;
      height: 100%;
      border-left: 1px solid #000;
      box-sizing: border-box;

      .el-tree {
        width: 98%;
        margin: 20px auto;
        background-color: transparent;
        color: #000;

        /deep/.el-tree-node__content {
          background-color: transparent;
        }
      }
    }

    .head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding-left: 12px;
      box-sizing: border-box;
      background-color: #07324E;
      color: #FFFFFF;
      font-size: 18px;
    }

    .no {
      width: 98%;
      height: 30px;
      line-height: 30px;
      margin: 20px auto;
      text-align: center;
      border: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 16px;
    }

    .table-title {
      width: 98%;
      margin: 0 auto;
      color: #000;
      font-size: 16px;

      span {
        margin-right: 40px;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 33px;
    box-sizing: border-box;
  }
}
</style>
