<template>
  <div class="details">
    <div class="details-title">
      <span>医生详情</span>
    </div>
    <div class="details-box">
      <div class="line">
        <span>医生信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>姓名：王爱飞</span>
        </div>
        <div class="item">
          <span>手机号码：12903210311</span>
        </div>
        <div class="item">
          <span>科室：内分泌科</span>
        </div>
        <div class="item">
          <span>职称：主任医师</span>
        </div>
        <div class="item item3">
          <span>擅长：山供电搜富哦腐啊福哦按腐啊发发发发发</span>
        </div>
        <div class="item item3">
          <span>头像</span>
          <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img :src="imageUrl" class="avatar">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="item item3">
          <span>简介：山供电搜富哦腐啊福哦按腐啊发发发发发</span>
        </div>
      </div>
      <div class="line">
        <span>问诊方式</span>
      </div>
      <div class="list">
        <div class="item">
          <span>图文问诊 ￥80</span>
        </div>
        <div class="item item2">
          <span>视频问诊 ￥80</span>
        </div>
      </div>
    </div>
    <div class="position">
      <div class="return blue" @click="openUpdate">
        <span>修改</span>
      </div>
      <div class="return" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      showUpdate: false,
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-title {
    color: #01EEFD;
    font-size: 20px;
  }

  .details-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .line {
      height: 50px;
      line-height: 50px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        width: 25%;
        margin-top: 20px;
        color: #01EEFD;
        font-size: 16px;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 75%;
      }

      .item3 {
        display: flex;
        align-items: center;
        width: 50%;
      }

      /deep/.avatar-uploader .el-upload {
        display: flex;
        align-items: center;
        margin-left: 20px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        margin-left: 20px;
        font-size: 20px;
        color: #01EEFD;
        text-align: center;
      }

      .avatar {
        width: 25px;
        height: 25px;
        display: block;
      }
    }
  }

  .position {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .return {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #33465C;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .blue {
    margin-right: 34px;
    background-color: #04B4D0;
  }
}
</style>
