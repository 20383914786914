<template>
  <div class="details">
    <div class="details-title">
      <span>就诊人详情</span>
      <el-input class="details-search" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="details-box">
      <div class="line">
        <span>就诊人基础信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>姓名：田田</span>
        </div>
        <div class="item">
          <span>性别：男</span>
        </div>
        <div class="item">
          <span>手机号：18812341235</span>
        </div>
        <div class="item">
          <span>身份证号码：27347203804238042  </span>
        </div>
        <div class="item item3">
          <span>联系地址：四川省成都市锦江区大十字路由2038号</span>
        </div>
        <div class="item item3">
          <span>身份证图片：</span>
          <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <!-- <img :src="imageUrl" class="avatar"> -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span class="">上传图片</span>
          </el-upload>
          <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <!-- <img :src="imageUrl" class="avatar"> -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span class="">上传图片</span>
          </el-upload>
        </div>
      </div>
      <div class="line">
        <span>健康档案</span>
      </div>
      <div class="list">
        <div class="item">
          <span>肝功能：正常</span>
        </div>
        <div class="item">
          <span>肾功能：正常 </span>
        </div>
        <div class="item">
          <span>过敏史：阿莫西林过敏</span>
        </div>
        <div class="item">
          <span>过往病史：糖尿病</span>
        </div>
      </div>
      <div class="line">
        <span>问诊记录</span>
      </div>
      <el-table :data="tableData" height="456" max-height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="时间">
        </el-table-column>
        <el-table-column prop="date" label="科室">
        </el-table-column>
        <el-table-column prop="type" label="医生">
        </el-table-column>
        <el-table-column prop="type1" label="病情描述">
        </el-table-column>
        <el-table-column label="操作">
          <!-- slot-scope="scope" -->
          <template>
            <div class="see">
              <span class="el-icon-view" @click="openConsultationDetails"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="position">
      <div class="return" @click="returnPage">
        <span>返回</span>
      </div>
    </div>
    <ConsultationDetails v-if="showConsultationDetails" @close="closeConsultationDetails"></ConsultationDetails>
  </div>
</template>

<script>
import ConsultationDetails from './consultationDetails.vue'
export default {
  components: {
    ConsultationDetails
  },
  data () {
    return {
      showUpdate: false,
      imageUrl: '',
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      showConsultationDetails: false
    }
  },
  methods: {
    openConsultationDetails () {
      this.showConsultationDetails = true
    },
    closeConsultationDetails () {
      this.showConsultationDetails = false
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #01EEFD;
    font-size: 20px;

    .details-search, /deep/.el-input__inner {
      width: 246px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%) !important;
      border: 1px solid #1F72D3;
      border-radius: 6px;
    }
  }

  .details-box {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      height: 50px;
      line-height: 50px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        width: 25%;
        margin-top: 20px;
        color: #01EEFD;
        font-size: 16px;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 75%;
      }

      .item3 {
        display: flex;
        align-items: center;
        width: 50%;
      }

      /deep/.avatar-uploader .el-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 157px;
        height: 77px;
        background-color: #fff;
        color: #B5B5B5;
        margin-left: 20px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 20px;
        color: #B5B5B5;
        text-align: center;
      }

      .avatar {
        width: 25px;
        height: 25px;
        display: block;
      }
    }
  }

  .position {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .see {
    cursor: pointer;
  }

  .return {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #33465C;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .blue {
    margin-right: 34px;
    background-color: #04B4D0;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
