<template>
  <div class="write">
    <div class="title">
      <span>修改</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="span">
          <span>姓名</span>
          <el-input></el-input>
        </div>
        <div class="span">
          <span>电话</span>
          <el-input></el-input>
        </div>
        <div class="span">
          <span>科室</span>
          <el-input></el-input>
        </div>
        <div class="span">
          <span>职称</span>
          <el-input></el-input>
        </div>
        <div class="span span1">
          <span>头像</span>
          <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img :src="imageUrl" class="avatar">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="span span2">
          <span>职称</span>
          <el-input type="textarea"  placeholder="请输入内容" >
          </el-input>
        </div>
        <div class="span span2">
          <span>职称</span>
          <el-input type="textarea"  placeholder="请输入内容" >
          </el-input>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      imageUrl: require('../../img/emr/user.png')
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 400px;
    margin-top: 10px;
    padding: 20px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 70px);
      color: #01EEFD;
      font-size: 18px;

      .span {
        display: flex;
        align-items: center;
        width: 33%;
        margin-bottom: 10px;

        span {
          white-space: nowrap;
          margin-right: 10px;
        }

        .el-input {
          width: 170px;
        }

        /deep/.avatar-uploader .el-upload {
          display: flex;
          align-items: center;
          margin-left: 20px;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }

        .avatar-uploader-icon {
          margin-left: 10px;
          font-size: 20px;
          color: #01EEFD;
          text-align: center;
        }

        .avatar {
          width: 25px;
          height: 25px;
          display: block;
        }

        /deep/.el-textarea__inner {
          width: 500px;
          resize: none;
        }
      }

      .span1 {
        width: 66%;
      }

      .span2 {
        width: 100%;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
