<template>
  <div class="children">
    <div class="children-title">
      <span>0-3岁女童生长发育监测图</span>
    </div>
    <div class="echarts" id="echarts"></div>
    <div class="echarts" id="echarts1"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      myChart: null,
      myChart1: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
      this.canvas1()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    getresize () {
      this.myChart.resize()
      this.myChart1.resize()
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '身高（cm）',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#FF0000', // 改变折线点的颜色
              lineStyle: {
                color: '#FF0000' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '体重（kg）',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#FF0000', // 改变折线点的颜色
              lineStyle: {
                color: '#FF0000' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .echarts {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }

}
</style>
