<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-05-22 17:51:18
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-17 15:52:44
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\diabetes\item1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="children">
    <div class="children-title">
      <span>2型糖尿病患者随访服务记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>随访时间</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>年 月 日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>随访方式</span>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr5 children-border">
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-title">
            <span>症状及体征：</span>
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="checks">
              <el-checkbox label="0" class="margin-bottom">无症状</el-checkbox>
              <el-checkbox label="1" class="margin-bottom">多饮</el-checkbox>
              <el-checkbox label="2" class="margin-bottom">多食</el-checkbox>
              <el-checkbox label="3" class="margin-bottom">多尿</el-checkbox>
              <el-checkbox label="4" class="margin-bottom">视力模糊</el-checkbox>
              <el-checkbox label="5" class="margin-bottom">感染</el-checkbox>
              <el-checkbox label="6" class="margin-bottom">手脚麻木</el-checkbox>
              <el-checkbox label="7" class="margin-bottom">下肢浮肿</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="children-td children-flex3">
          <span>其他：</span>
        </div>
        <div class="children-td children-flex3">
          <span>其他：</span>
        </div>
        <div class="children-td children-flex3">
          <span>其他：</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span>其他：</span>
        </div>
      </div>

      <div class="children-tr children-tr10 children-border">
        <div class="children-td children-flex3">
          <div class="children-td-left children-border-right children-td-text1">
            <span>体征</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>血压（mmHg）</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>体重（kg）</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>体质指数（BMI）（kg/m2）</span>
            </div>
            <div class="children-td-list children-td-list1 children-border-bottom children-td-list-center">
              <span>足背动脉搏动</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>其他</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-td-list1 children-border-bottom children-text-center">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0" class="margin-bottom">触及正常</el-radio>
              <el-radio label="1" class="margin-bottom">减弱（双侧 左侧 右侧）</el-radio>
              <el-radio label="2">消失（双侧 左侧 右侧）</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <span class="margin-left"></span>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-td-list1 children-border-bottom children-text-center">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0" class="margin-bottom">触及正常</el-radio>
              <el-radio label="1" class="margin-bottom">减弱（双侧 左侧 右侧）</el-radio>
              <el-radio label="2">消失（双侧 左侧 右侧）</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <span class="margin-left"></span>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-td-list1 children-border-bottom children-text-center">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0" class="margin-bottom">触及正常</el-radio>
              <el-radio label="1" class="margin-bottom">减弱（双侧 左侧 右侧）</el-radio>
              <el-radio label="2">消失（双侧 左侧 右侧）</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <span class="margin-left"></span>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom children-text-center">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-td-list1 children-border-bottom children-text-center">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0" class="margin-bottom">触及正常</el-radio>
              <el-radio label="1" class="margin-bottom">减弱（双侧 左侧 右侧）</el-radio>
              <el-radio label="2">消失（双侧 左侧 右侧）</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <span class="margin-left"></span>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr10 children-border">
        <div class="children-td children-flex3">
          <div class="children-td-left children-td-text children-border-right">
            <span>生活方式指导</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>日吸烟量（支）</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>日饮酒量（两）</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>运动</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>摄盐情况（咸淡）</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>心理调整</span>
            </div>
            <div class="children-td-list children-td-list-center children-tr8">
              <span>遵医行为</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <span class="color">次/周分钟</span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">轻</el-radio>
              <el-radio label="1">中</el-radio>
              <el-radio label="2">重</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <span class="color">次/周分钟</span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">轻</el-radio>
              <el-radio label="1">中</el-radio>
              <el-radio label="2">重</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <span class="color">次/周分钟</span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">轻</el-radio>
              <el-radio label="1">中</el-radio>
              <el-radio label="2">重</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <span class="color">次/周分钟</span>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">轻</el-radio>
              <el-radio label="1">中</el-radio>
              <el-radio label="2">重</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center children-border-bottom">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-text-center">
            <el-radio-group v-model="checks" class="margin-left">
              <el-radio label="0">良好</el-radio>
              <el-radio label="1">一般</el-radio>
              <el-radio label="2">差</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>辅助检查</span>
        </div>
        <div class="children-td children-flex3">

        </div>
        <div class="children-td children-flex3">

        </div>
        <div class="children-td children-flex3">

        </div>
        <div class="children-td children-flex3 children-border-right-none">

        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>服药依从性</span>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">规律</el-radio>
            <el-radio label="1">间断</el-radio>
            <el-radio label="2">不服药</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">规律</el-radio>
            <el-radio label="1">间断</el-radio>
            <el-radio label="2">不服药</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">规律</el-radio>
            <el-radio label="1">间断</el-radio>
            <el-radio label="2">不服药</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left children-border-right-none">
            <el-radio label="0">规律</el-radio>
            <el-radio label="1">间断</el-radio>
            <el-radio label="2">不服药</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>药物不良反应</span>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
            <!-- <el-input class="input1"></el-input> -->
          </el-radio-group>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
            <!-- <el-input class="input1"></el-input> -->
          </el-radio-group>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
            <!-- <el-input class="input1"></el-input> -->
          </el-radio-group>
        </div>
        <div class="children-td children-text-center  children-flex3 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
            <!-- <el-input class="input1"></el-input> -->
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>此次随访分类</span>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">控制满意</el-radio>
            <el-radio label="1">控制不满意</el-radio>
            <el-radio label="2">不良反应</el-radio>
            <el-radio label="3">并发症</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">控制满意</el-radio>
            <el-radio label="1">控制不满意</el-radio>
            <el-radio label="2">不良反应</el-radio>
            <el-radio label="3">并发症</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-text-center  children-flex3">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">控制满意</el-radio>
            <el-radio label="1">控制不满意</el-radio>
            <el-radio label="2">不良反应</el-radio>
            <el-radio label="3">并发症</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-text-center children-flex3 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">控制满意</el-radio>
            <el-radio label="1">控制不满意</el-radio>
            <el-radio label="2">不良反应</el-radio>
            <el-radio label="3">并发症</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr4 children-border">
        <div class="children-td children-flex3">
          <div class="children-td-left children-td-text children-border-right">
            <span>用药情况</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>药物名称1</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>用法用量</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>药物名称2</span>
            </div>
            <div class="children-td-list children-td-list-center children-tr8">
              <span>用法用量</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list">
            <div class="children-td children-flex1 children-td-list-center children-td-column">
              <span>每日   次</span>
            </div>
            <div class="children-td children-td-list-center children-flex1 children-td-column children-border-right-none ">
              <span>每次</span>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr11 children-border">
        <div class="children-td children-flex3">
          <div class="children-td-left children-td-text children-border-right">
            <span>转诊</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>原因</span>
            </div>
            <div class="children-td-list children-td-list-center children-tr8">
              <span>机构及科别</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-text-center">

          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-text-center">

          </div>
        </div>
        <div class="children-td children-flex3 children-td-column">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-text-center">

          </div>
        </div>
        <div class="children-td children-flex3 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"></span>
          </div>
          <div class="children-td-list children-text-center children-border-right-none">

          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>下次随访时间：</span>
        </div>
        <div class="children-td children-flex12 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>随访医生签名：</span>
        </div>
        <div class="children-td children-flex12 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #fff;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-tr10 {
      height: 300px;
    }

    .children-tr11 {
      height: 100px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        color: #fff;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;
    }

    .children-td-list1 {
      height: 100px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 6px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      // padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
