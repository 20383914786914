<template>
  <div class="details">
    <div class="details-title">
      <span>处方详情</span>
    </div>
    <div class="details-box">
      <div class="line">
        <span>订单信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span>订单编号：20200408121324</span>
        </div>
        <div class="item">
          <span>药品类型：中药</span>
        </div>
        <div class="item">
          <span>所属药房：互联网药房</span>
        </div>
        <div class="item">
          <span>状态：待支付</span>
        </div>

        <div class="item">
          <span>配药模式：物流配送 </span>
        </div>
        <div class="item">
          <span>收货人：张三</span>
        </div>
        <div class="item">
          <span>电话：1820293094 </span>
        </div>
        <div class="item">
          <span>地址：贵州省贵阳市观山湖区卡卡各各</span>
        </div>

        <div class="item">
          <span>发药状态：已配送</span>
        </div>
        <div class="item">
          <span>物流公司：顺丰</span>
        </div>
        <div class="item">
          <span>物流单号：021808083208</span>
        </div>
        <div class="item">
          <span>创建时间：2020-03-04  11：08：18</span>
        </div>
      </div>
      <div class="line">
        <span>药品明细</span>
      </div>
      <div class="list">
        <div class="item item1">
          <span>Rp</span>
        </div>
        <div class="item item1">
          <span>【络活喜】苯磺酸氨路地平片           ×1</span>
        </div>
        <div class="item item1">
          <span>规格：5mg*7片/盒</span>
        </div>
        <div class="item item1">
          <span>口服：成人一次5mg（1片）。每天一次</span>
        </div>
        <div class="item item1">
          <span>【络活喜】苯磺酸氨路地平片           ×1</span>
        </div>
      </div>
    </div>
    <div class="return" @click="returnPage">
      <span>返回</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-title {
    color: #01EEFD;
    font-size: 20px;
  }

  .details-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .line {
      height: 50px;
      line-height: 50px;
      padding: 0 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 18px;
      box-sizing: border-box;

      .item {
        width: 25%;
        margin-top: 20px;
        color: #01EEFD;
        font-size: 16px;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 75%;
      }

      .item3 {
        width: 50%;
      }
    }
  }

  .return {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background-color: #33465C;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
