<template>
  <div class="children">
    <div class="children-title">
      <span>第1次产前检查服务记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <!-- <div class="position">
        <span>产科检</span>
      </div> -->
      <div class="children-tr children-border">
        <div class="children-td children-flex2 children-border-right-none">
          <span>填表日期</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex2 children-text-center ">
          <span>孕周</span>
        </div>
        <div class="children-td children-flex5 children-text-center children-border-right-none">
          <span>周</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>孕妇年龄</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>丈夫姓名</span>
        </div>
        <div class="children-td children-flex2">
          <span></span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>丈夫年龄</span>
        </div>
        <div class="children-td children-flex2 children-text-center">
          <span>丈夫电话</span>
        </div>
        <div class="children-td children-flex3 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>孕次</span>
        </div>
        <div class="children-td children-flex2">
          <span></span>
        </div>
        <div class="children-td children-flex8 children-text-center children-border-three">
          <span>产次</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>末次月经</span>
        </div>
        <div class="children-td children-flex2">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex8 children-text-center children-border-three">
          <span>预产期</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>既往史</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">心脏病</el-checkbox>
            <el-checkbox label="2">肾脏疾病</el-checkbox>
            <el-checkbox label="3">肝脏疾病</el-checkbox>
            <el-checkbox label="4">高血压</el-checkbox>
            <el-checkbox label="5">贫血</el-checkbox>
            <el-checkbox label="6">糖尿病</el-checkbox>
            <el-checkbox label="7">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>家族史</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">遗传性疾病史</el-checkbox>
            <el-checkbox label="2">精神疾病史</el-checkbox>
            <el-checkbox label="3">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>个人史</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">吸烟</el-checkbox>
            <el-checkbox label="2">饮酒</el-checkbox>
            <el-checkbox label="3">服用药物</el-checkbox>
            <el-checkbox label="4">接触有毒有害物质</el-checkbox>
            <el-checkbox label="5">接触放射线</el-checkbox>
            <el-checkbox label="6">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>妇产科手术史</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>孕产史</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">自然流产</el-checkbox>
            <el-checkbox label="1">人工流产</el-checkbox>
            <el-checkbox label="2">死胎</el-checkbox>
            <el-checkbox label="3">死产</el-checkbox>
            <el-checkbox label="4">新生儿死亡</el-checkbox>
            <el-checkbox label="5">出生缺陷儿</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>身高</span>
        </div>
        <div class="children-td children-flex10 children-text-center   children-border-right-none children-border-three">
          <div class="children-td children-flex4 children-text-center">
            <span>cm</span>
          </div>
          <div class="children-td children-flex4 children-text-center">
            <span>体重</span>
          </div>
          <div class="children-td children-flex4 children-text-center   children-border-right-none">
            <span>Kg</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>体质指数</span>
        </div>
        <div class="children-td children-flex10 children-text-center   children-border-right-none children-border-three">
          <div class="children-td children-flex1 children-text-center">
            <span>kg/m2</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>血压</span>
          </div>
          <div class="children-td children-flex1 children-text-center   children-border-right-none">
            <span>/ mmHg</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>听诊</span>
        </div>
        <div class="children-td children-flex10 children-text-center   children-border-right-none children-border-three">
          <div class="children-td children-flex1">
            <span class="margin-left">心脏</span>
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">未见异常</el-radio>
              <el-radio label="1">异常</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td children-flex1 children-border-right-none">
            <span class="margin-left">肺部</span>
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">未见异常</el-radio>
              <el-radio label="1">异常</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr4 children-border">
        <div class="children-td children-flex2">
          <span>妇科检查</span>
        </div>
        <div
          class="children-td children-flex10 children-text-center   children-border-right-none children-td-column children-border-three">
          <div class="children-tr children-tr1 children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">外阴</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未见异常</el-radio>
                <el-radio label="1">异常</el-radio>
              </el-radio-group>
            </div>
            <div class="children-td children-flex1 children-border-right-none ">
              <span class="margin-left">阴道</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未见异常</el-radio>
                <el-radio label="1">异常</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-tr1 children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">宫颈</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未见异常</el-radio>
                <el-radio label="1">异常</el-radio>
              </el-radio-group>
            </div>
            <div class="children-td children-flex1 children-border-right-none">
              <span class="margin-left">子宫</span>
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未见异常</el-radio>
                <el-radio label="1">异常</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-tr1">
            <div class="children-td children-flex1 children-border-right-none">
              <span class="margin-left">附件</span>
            </div>
          </div>

        </div>
      </div>

      <div class="children-tr children-tr6 children-border">
        <div class="children-td children-flex2">
          <span>辅助检查</span>
        </div>
        <div
          class="children-td children-flex10 children-text-center   children-border-right-none children-td-column children-border-three">
          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">血常规</span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
              <span class="margin-left">血红蛋白值 </span>
              <span>g/L </span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
              <span class="margin-left">白细胞计数值 </span>
              <span>/L </span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
              <span class="margin-left">血小板计数值</span>
              <span>g/L </span>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">尿常规</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-checkbox-group v-model="checks">
                <el-checkbox label="0">尿蛋白</el-checkbox>
                <el-checkbox label="1">尿糖</el-checkbox>
                <el-checkbox label="2">尿酮体</el-checkbox>
                <el-checkbox label="3">尿潜血</el-checkbox>
                <el-checkbox label="4">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">血型 ABO</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none "></div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">血型 Rh*</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none "></div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">肝功能</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <div class="children-td children-flex1  children-border-right-none">
                <span class="margin-left">血清谷丙转氨酶 U/L</span>
              </div>
              <div class="children-td children-flex1 children-text-center children-border-right-none">
                <span class="margin-left">血清谷草转氨酶 U/L</span>
              </div>
              <div class="children-td children-flex1 children-text-center children-border-right-none">
                <span class="margin-left">白蛋白 g/L</span>
              </div>
              <div class="children-td children-flex1 children-text-center children-border-right-none">
                <span class="margin-left">总胆红素 μmol/L</span>
              </div>
              <div class="children-td children-flex1 children-text-center children-border-right-none">
                <span class="margin-left">结合胆红素 μmol/L </span>
              </div>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">肾功能</span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
              <span class="margin-left">血清肌酐 μmol/L</span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
              <span class="margin-left">血尿素 mmol/L</span>
            </div>
            <div class="children-td children-flex2 children-border-right-none ">
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">阴道分泌物*</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">未见异常</el-radio>
                <el-radio label="1">滴虫</el-radio>
                <el-radio label="2">假丝酵母菌</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">阴道清洁度：</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">Ⅰ度</el-radio>
                <el-radio label="1">Ⅱ度</el-radio>
                <el-radio label="2">Ⅲ度</el-radio>
                <el-radio label="3">Ⅳ度</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">乙型肝炎</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">乙型肝炎表面抗原乙型肝炎表面抗体*</el-radio>
                <el-radio label="1">乙型肝炎e抗原*</el-radio>
                <el-radio label="2">乙型肝炎e抗体*</el-radio>
                <el-radio label="3">乙型肝炎核心抗体</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">梅毒血清学试验</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">阴性</el-radio>
                <el-radio label="1">阳性</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">HIV抗体检测</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none ">
              <el-radio-group v-model="sex" class="margin-left">
                <el-radio label="0">阴性</el-radio>
                <el-radio label="1">阳性</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-tr children-border-bottom">
            <div class="children-td children-flex1">
              <span class="margin-left">B超</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none "></div>
          </div>

          <div class="children-tr">
            <div class="children-td children-flex1">
              <span class="margin-left">其他</span>
            </div>
            <div class="children-td children-flex6 children-border-right-none "></div>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>总体评估</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>保健指导</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-checkbox-group v-model="checks">
            <el-checkbox label="0">生活方式</el-checkbox>
            <el-checkbox label="1">心理</el-checkbox>
            <el-checkbox label="2">营养</el-checkbox>
            <el-checkbox label="3">避免致畸因素和疾病对胚胎的不良影响</el-checkbox>
            <el-checkbox label="4">产前筛查宣传告知</el-checkbox>
            <el-checkbox label="5">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-tr1 children-border">
        <div class="children-td children-flex children-flex2">
          <span>转诊建议：</span>
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <div class="children-td children-flex1 children-border-right-none">
            <span>原因：</span>
          </div>
          <div class="children-td children-flex1 children-border-right-none">
            <span>机构及科室：</span>
          </div>
          <div class="children-td children-flex1 children-border-right-none">
            <span></span>
          </div>
          <div class="children-td children-flex1 children-border-right-none">
            <span></span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>下次随访地点：</span>
        </div>
        <div class="children-td children-flex1">
          <span>年     月    日</span>
        </div>
        <div class="children-td children-flex1">
          <span>随访医生签名</span>
        </div>
        <div class="children-td children-flex1 children-border-right-none"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #081C38;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }
    }

    .children-td-column {
      flex-direction: column;
    }

    .margin-left {
      margin-left: 14px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
