<template>
  <div class="update">
    <div class="update-box">
      <div class="update-head">
        <div class="update-text"></div>
        <div class="update-text">
          <span>编辑</span>
        </div>
        <div class="update-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="update-content">
        <div class="update-item">
          <div class="update-key">
            <span>上级菜单</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>名称</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>样式</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-item1">
          <div class="update-key">
            <span>链接</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>类型</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>权限</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>排序</span>
          </div>
          <el-input v-model="input" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="update-footer">
        <el-button class="gray" @click="close">取消</el-button>
        <el-button @click="close">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: '',
      input: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);

  .update-box {
    overflow: hidden;
    width: 70%;
    height: 50%;
    background-color: #fff;
    border-radius: 6px;

    .update-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      padding: 0 21px;
      background-color: #006EFF;
      box-sizing: border-box;

      .update-text {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }

      .update-close {
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
    }

    .update-content {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: calc(100% - 115px);
      padding: 0 30px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .update-item {
        display: flex;
        align-items: center;
        width: 33%;

        .update-key {
          width: 90px;
          color: #17212B;
          font-size: 18px;
        }

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }
      }

      .update-item1 {
        display: flex;
        align-items: center;
        width: 70%;

        .update-key {
          width: 90px;
          color: #17212B;
          font-size: 18px;
        }
      }
    }

    .update-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;

      .el-button {
        margin-right: 30px;
        padding: 5px 35px;
        background-color: #006EFF;
        border-radius: 6px;
        color: #fff;
        font-size: 22px;
      }

      .gray {
        background-color: #999999;
      }
    }
  }
}
</style>
