<template>
  <div class="children">
    <div class="children-title">
      <span>产后42天健康检查记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>随访日期</span>
        </div>
        <div class="children-td children-flex9 children-text-center children-border-right-none">
          <span>年  月  日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>分泌日期</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>年  月  日</span>
        </div>
        <div class="children-td children-flex3 children-text-center">
          <span>出院日期</span>
        </div>
        <div class="children-td children-flex3 children-text-center children-border-right-none">
          <span>年  月  日</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>一般心理状况</span>
        </div>
        <div class="children-td children-flex9 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-tr1 children-border">
        <div class="children-td children-flex2">
          <span>一般健康情况</span>
        </div>
        <div class="children-td children-flex9 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>血压(mmHg)</span>
        </div>
        <div class="children-td children-flex9 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>乳房</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>恶露</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>子宫</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>伤口</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr1 children-border">
        <div class="children-td children-flex2">
          <span>其他</span>
        </div>
        <div class="children-td children-flex9 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>分类</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">已恢复</el-radio>
            <el-radio label="1">未恢复</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>指导</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">心理保健</el-checkbox>
            <el-checkbox label="1">性保健与避孕</el-checkbox>
            <el-checkbox label="2">婴儿喂养</el-checkbox>
            <el-checkbox label="3">产妇营养</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-tr2 children-border">
        <div class="children-td children-flex2">
          <span>转诊建议：</span>
        </div>
        <div class="children-td children-flex9 children-wrap">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>下次随访地点：</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none"></div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>随访医生签名：</span>
        </div>
        <div class="children-td children-flex9 children-border-right-none"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 358px;
      left: 1px;
      width: 67px;
      height: 347px;
      background-color: #081C38;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode:vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .group {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
    }

    .group-width {
      width: 100%;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex9 {
      flex: 9;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
