<template>
  <div class="tab-box">
    <div class="tab-box-item">
      <div class="tab-box-title">
        <span>互联网药房</span>
      </div>
      <el-tree :data="data" show-checkbox node-key="id" :default-expanded-keys="[1]"
        :props="defaultProps">
      </el-tree>
    </div>
    <div class="tab-box-item">
      <div class="tab-box-title">
        <span>院内菜单</span>
      </div>
      <el-tree :data="data" show-checkbox node-key="id" :default-expanded-keys="[1]"
        :props="defaultProps">
      </el-tree>
    </div>
    <el-button size="small" class="button">保存</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: [
        {
          id: 1,
          label: 'A层级菜单1',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        },
        {
          id: 2,
          label: 'A层级菜单2',
          children: []
        },
        {
          id: 3,
          label: 'A层级菜单3',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  }
}
</script>

<style scoped lang="less">
.tab-box {
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 120px);

  .tab-box-item {
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .tab-box-title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
    color: #01EEFD;
    font-size: 20px;
  }

  .el-tree {
    background-color: transparent;
    color: #01EEFD;
    font-size: 18px;

    /deep/.el-tree-node__content:hover {
      background-color: transparent;
    }

    /deep/.el-tree-node:focus>.el-tree-node__content {
      background-color: transparent;
    }
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    color: #fff;
  }
}
</style>
