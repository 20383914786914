<template>
  <div class="children">
    <div class="children-title">
      <span>3～6岁儿童健康检查记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <div class="position">
        <span>体格检查</span>
      </div>
      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>月龄</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span>12月龄</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span>18月龄</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span>24月龄</span>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <span>30月龄</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>随访日期</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>体重：</span>
          <span class="input"></span>
          <span>kg</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>身长：</span>
          <span class="input"></span>
          <span>cm</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-radio-group v-model="sex">
            <el-radio label="0">上</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">下</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>头围：</span>
          <span class="input"></span>
          <span>cm</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span></span>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-tr1 children-border">
        <div class="children-td children-flex1">
          <span>体格发育评价</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex" class="margin-left group">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">低体重</el-radio>
            <el-radio label="2">消瘦</el-radio>
            <el-radio label="3">生长迟缓</el-radio>
            <el-radio label="4">超重</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex" class="margin-left group">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">低体重</el-radio>
            <el-radio label="2">消瘦</el-radio>
            <el-radio label="3">生长迟缓</el-radio>
            <el-radio label="4">超重</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex" class="margin-left group">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">低体重</el-radio>
            <el-radio label="2">消瘦</el-radio>
            <el-radio label="3">生长迟缓</el-radio>
            <el-radio label="4">超重</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left group">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">低体重</el-radio>
            <el-radio label="2">消瘦</el-radio>
            <el-radio label="3">生长迟缓</el-radio>
            <el-radio label="4">超重</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>视力</span>
        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">

        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>听力</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">通过</el-radio>
            <el-radio label="1">未通过</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
        </div>
        <div class="children-td children-flex1 children-text-center">
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>牙数（颗）/龋齿数</span>
        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center">

        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">

        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>胸部</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>腹部</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-radio-group v-model="sex">
            <el-radio label="0">未见异常</el-radio>
            <el-radio label="1">异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>血红蛋白值</span>
        </div>
        <div class="children-td children-flex1">
        </div>
        <div class="children-td children-flex1">
        </div>
        <div class="children-td children-flex1 children-text-center">
          <span class="input"></span>
          <span>g/L</span>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <span class="input"></span>
          <span>g/L</span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1 children-text-right">
          <span>其他</span>
        </div>
        <div class="children-td children-flex1">
        </div>
        <div class="children-td children-flex1">
        </div>
        <div class="children-td children-flex1 children-text-center"></div>
        <div class="children-td children-flex1 children-text-center children-border-right-none"></div>
      </div>

      <div class="children-tr children-tr3 children-border">
        <div class="children-td children-flex1">
          <span>发育评估</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="group checkbox-width">
            <el-checkbox label="0">不会说自己的名字</el-checkbox>
            <el-checkbox label="1">不会玩“拿棍当马骑”等假想游戏</el-checkbox>
            <el-checkbox label="2">不会模仿画圆</el-checkbox>
            <el-checkbox label="3">不会双脚跳</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="group">
            <el-checkbox label="0">不会说带形容词的句子</el-checkbox>
            <el-checkbox label="1">不能按要求等待或轮流</el-checkbox>
            <el-checkbox label="2">不会独立穿衣</el-checkbox>
            <el-checkbox label="3">不会单脚站</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="group">
            <el-checkbox label="0">不能简单叙说事情经过</el-checkbox>
            <el-checkbox label="1">不知道自己的性别</el-checkbox>
            <el-checkbox label="2">不会用筷子吃饭</el-checkbox>
            <el-checkbox label="3">会单脚跳</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-checkbox-group v-model="checks" class="group">
            <el-checkbox label="0">不会表达自己的感受或想法</el-checkbox>
            <el-checkbox label="1">不会玩角色扮演的集体游戏</el-checkbox>
            <el-checkbox label="2">不会画方形</el-checkbox>
            <el-checkbox label="3">不会奔跑</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-tr1 children-border">
        <div class="children-td children-flex1">
          <span>两次随访间患病情况</span>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">肺炎次</el-checkbox>
            <el-checkbox label="2">腹泻次</el-checkbox>
            <el-checkbox label="3">外伤次</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">肺炎次</el-checkbox>
            <el-checkbox label="2">腹泻次</el-checkbox>
            <el-checkbox label="3">外伤次</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">肺炎次</el-checkbox>
            <el-checkbox label="2">腹泻次</el-checkbox>
            <el-checkbox label="3">外伤次</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-text-center children-border-right-none">
          <el-checkbox-group v-model="checks" class="margin-left">
            <el-checkbox label="0">无</el-checkbox>
            <el-checkbox label="1">肺炎次</el-checkbox>
            <el-checkbox label="2">腹泻次</el-checkbox>
            <el-checkbox label="3">外伤次</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-tr3 children-border">
        <div class="children-td children-flex1">
          <span>转诊建议：</span>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
          <div class="label">
            <span>原因：</span>
          </div>
          <div class="label">
            <span>机构及科室：</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr2 children-border">
        <div class="children-td children-flex1">
          <span>指导：</span>
        </div>
        <div class="children-td children-flex1">
          <el-checkbox-group v-model="checks" class="group group-center margin-left">
            <el-checkbox label="0">科学喂养</el-checkbox>
            <el-checkbox label="1">生长发育</el-checkbox>
            <el-checkbox label="2">疾病预防</el-checkbox>
            <el-checkbox label="3">预防伤害</el-checkbox>
            <el-checkbox label="4">口腔保健</el-checkbox>
            <el-checkbox label="5">
              其他
              <el-input class="input input1"></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-checkbox-group v-model="checks" class="group group-center margin-left">
            <el-checkbox label="0">科学喂养</el-checkbox>
            <el-checkbox label="1">生长发育</el-checkbox>
            <el-checkbox label="2">疾病预防</el-checkbox>
            <el-checkbox label="3">预防伤害</el-checkbox>
            <el-checkbox label="4">口腔保健</el-checkbox>
            <el-checkbox label="5">
              其他
              <el-input class="input input1"></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-checkbox-group v-model="checks" class="group group-center margin-left">
            <el-checkbox label="0">科学喂养</el-checkbox>
            <el-checkbox label="1">生长发育</el-checkbox>
            <el-checkbox label="2">疾病预防</el-checkbox>
            <el-checkbox label="3">预防伤害</el-checkbox>
            <el-checkbox label="4">口腔保健</el-checkbox>
            <el-checkbox label="5">
              其他
              <el-input class="input input1"></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="children-td children-flex1 children-wrap">
          <el-checkbox-group v-model="checks" class="group group-center margin-left">
            <el-checkbox label="0">科学喂养</el-checkbox>
            <el-checkbox label="1">生长发育</el-checkbox>
            <el-checkbox label="2">疾病预防</el-checkbox>
            <el-checkbox label="3">预防伤害</el-checkbox>
            <el-checkbox label="4">口腔保健</el-checkbox>
            <el-checkbox label="5">
              其他
              <el-input class="input input1"></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>下次随访地点：</span>
        </div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1 children-border-right-none"></div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <span>随访医生签名：</span>
        </div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1"></div>
        <div class="children-td children-flex1 children-border-right-none"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 358px;
      left: 2px;
      width: 67px;
      height: 347px;
      background-color: #fff;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode:vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .group {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
    }

    .checkbox-width {
      width: 230px;

      /deep/.el-checkbox__label {
        white-space: pre-wrap;
      }
    }

    .group-width {
      width: 100%;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
