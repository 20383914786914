<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-05-20 15:03:23
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-17 15:56:07
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\phthisis\item1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="children">
    <div class="children-title">
      <span>肺结核患者第一次入户随访记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：程霞</div>
      <div class="item">编号：</div>
    </div>
    <div class="children-table children-border-bottom">
      <!-- <div class="position">
        <span>产科检</span>
      </div> -->
      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>随访时间</span>
        </div>
        <div class="children-td children-flex3 children-text-center children-border-right-none">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
        <div class="children-td children-flex5 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>本次随访形式</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="2">电话</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>患者类型</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">初治</el-radio>
            <el-radio label="1">复治</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>痰菌情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">阳性</el-radio>
            <el-radio label="1">阴性</el-radio>
            <el-radio label="2">未查痰</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>耐药情况</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">耐药</el-radio>
            <el-radio label="1">非耐药 </el-radio>
            <el-radio label="2">未检测</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr5 children-border">
        <div class="children-td children-flex2 children-td-column">
          <div class="children-td-title">
            <span>症状及体征：</span>
          </div>
          <div class="checkbox">
            <el-checkbox-group v-model="checks">
              <el-checkbox label="0" class="margin-bottom">没有症状</el-checkbox>
              <el-checkbox label="1" class="margin-bottom">咳嗽咳痰</el-checkbox>
              <el-checkbox label="2" class="margin-bottom">低热盗汗</el-checkbox>
              <el-checkbox label="3" class="margin-bottom">咯血或血痰</el-checkbox>
              <el-checkbox label="4" class="margin-bottom">胸痛消瘦</el-checkbox>
              <el-checkbox label="5" class="margin-bottom">恶心纳差</el-checkbox>
              <el-checkbox label="6" class="margin-bottom">头痛失眠</el-checkbox>
              <el-checkbox label="7" class="margin-bottom">视物模糊</el-checkbox>
              <el-checkbox label="8" class="margin-bottom">皮肤瘙痒、皮疹</el-checkbox>
              <el-checkbox label="9" class="margin-bottom">耳鸣、听力下降</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <span>其他：</span>
        </div>
      </div>

      <div class="children-tr children-tr7 children-border">
        <div class="children-td children-flex2">
          <div class="children-td-left children-td-text children-border-right">
            <span>用药</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>化疗方案</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>用法</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>药品剂型</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <span></span>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">每日</el-radio>
              <el-radio label="1">间歇</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list">
            <el-checkbox-group v-model="checks" class="margin-left">
              <el-checkbox label="0">固定剂量复合制剂</el-checkbox>
              <el-checkbox label="1">散装药</el-checkbox>
              <el-checkbox label="2">板式组合药</el-checkbox>
              <el-checkbox label="3">注射剂</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>督导人员选择</span>
        </div>
        <div class="children-td children-flex10 children-border-three">
          <el-radio-group v-model="sex" class="margin-left">
            <el-radio label="0">医生</el-radio>
            <el-radio label="1">家属</el-radio>
            <el-radio label="2">自服药</el-radio>
            <el-radio label="3">其他</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <div class="children-td-left children-border-right children-td-text1">
            <span>家庭居住环境评估</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>单独的居室</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>通风情况</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list">
            <el-checkbox-group v-model="checks" class="margin-left">
              <el-checkbox label="0">良好</el-checkbox>
              <el-checkbox label="1">一般</el-checkbox>
              <el-checkbox label="2">较差</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr8 children-border">
        <div class="children-td children-flex2">
          <div class="children-td-left children-border-right children-td-text1">
            <span>生活方式评估</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>吸烟</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>饮酒</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <span class="margin-left"> 支/天</span>
          </div>
          <div class="children-td-list">
            <span class="margin-left"> 两/天</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr9 children-border">
        <div class="children-td children-flex2">
          <div class="children-td-left children-td-text children-border-right">
            <span>健康教育及培训</span>
          </div>
          <div class="children-td-right">
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>取药地点、时间</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>服药记录卡的填写</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>服药方法及药品存</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>肺结核治疗疗程</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>不规律服药危害</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>服药后不良反应及处</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>治疗期间复诊查痰</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>外出期间如何坚持服药</span>
            </div>
            <div class="children-td-list children-border-bottom children-td-list-center">
              <span>生活习惯及注意事项</span>
            </div>
            <div class="children-td-list children-td-list-center">
              <span>药品剂型</span>
            </div>
          </div>
        </div>
        <div class="children-td children-flex10 children-td-column children-border-right-none">
          <div class="children-td-list children-border-bottom">
            <div class="children-td children-flex1 children-border-right-none">
              <span>地点: </span>
            </div>
            <div class="children-td children-flex1 children-text-center children-border-right-none">
              <span>时间: 年 月 日</span>
            </div>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list children-border-bottom">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td-list">
            <el-radio-group v-model="sex" class="margin-left">
              <el-radio label="0">掌握</el-radio>
              <el-radio label="1">未掌握</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>下次随访时间</span>
        </div>
        <div class="children-td children-flex3 children-text-center children-border-right-none">
          <span>年 月 日</span>
        </div>
        <div class="children-td children-flex2 children-border-right-none">
          <span></span>
        </div>
        <div class="children-td children-flex5 children-border-right-none">
          <span></span>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex2">
          <span>下次随访地点：</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: []
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #fff;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      // padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .el-checkbox-group {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
    }

    .el-checkbox {
      margin-right: 6px;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #000;
      border-color: #000;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-checkbox-group {
      // padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

}
</style>
