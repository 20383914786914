<template>
  <div class="tab">
    <div class="tab-head">
      <div class="tab-head-left">
        <div class="tab-text">
          <span>角色</span>
        </div>
        <el-select v-model="value" filterable placeholder="请输入">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="tab-head-right">
        <el-button type="primary">提交</el-button>
        <el-button type="primary">清空</el-button>
      </div>
    </div>
    <div class="tab-add">
      <el-button type="primary" icon="el-icon-plus" circle></el-button>
    </div>
    <div class="tab-table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="id" label="角色">
        </el-table-column>
        <el-table-column prop="id" label="描述">
        </el-table-column>
        <el-table-column prop="id" label="修改时间">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openUpdate">编辑</span>
              <span>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tab-pagination">
      <!-- hide-on-single-page -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
        background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
      </el-pagination>
    </div>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      options: [],
      value: '',
      tableData: [{}],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      showUpdate: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;

  .tab-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    background-color: #fff;
    border-radius: 18px;
    box-sizing: border-box;

    .tab-head-left {
      display: flex;
      align-items: center;

      .tab-text {
        margin-right: 49px;
        color: #000;
        font-size: 20px;
      }
    }

    .tab-head-right {
      display: flex;
      align-items: center;

      .el-button {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 22px;
      }
    }
  }

  .tab-add {
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 34px;
    box-sizing: border-box;
  }

  .tab-table {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 230px);
    background-color: #fff;
    border-radius: 18px 18px 0 0;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
      font-size: 16px;
    }

    .flex {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      color: #399BFB;
      cursor: pointer;
    }
  }

  .tab-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-radius: 0 0 18px 18px;
  }
}
</style>
